/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	Handlebars.registerHelper('json', function(context) {
		return JSON.stringify(context);
	});

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			enabled_submit_siguiente: function () {
				var _this = this;
				if ( user_data && user_data.edo_cliente_data ) {
					$('.btn-siguiente-resumen').fadeIn(0);
					$('.btn-submit-add-to-cart').fadeIn(0);
				} else {
					//$('.btn-submit-add-to-cart').fadeOut(0);
				}
				if ( $('[name="edo_cliente_direccion_sede"]').val() ) {
					$('.btn-siguiente-resumen').removeAttr("disabled");
				} else {
					$('.btn-siguiente-resumen').attr("disabled", true);
				}
			},
			enabled_submit: function () {
				var _this = this;
				if ( user_data && user_data.edo_cliente_data ) {
					$('.btn-siguiente-resumen').fadeIn(0);
					$('.btn-submit-add-to-cart').fadeIn(0);
				} else {
					//$('.btn-submit-add-to-cart').fadeOut(0);
				}
				var $select_cliente = $('#clietes_direcciones .form_clientes input[type="radio"]:checked');
				if ( $select_cliente.length ) {
					var $select_direction = $('#clietes_direcciones .form_cliente_direcciones input[type="radio"]:checked');
					if ( $select_direction.length ) {
						if ( $select_direction.val() === -1 ) {
							var missing = false;
							if ( !$('#completar_registro_form [name="edo_cliente_direccion_direcc"]').val() ) {
								missing = true;
							}
							/*
							if ( !$('#completar_registro_form [name="edo_cliente_direccion_refer"]').val() ) {
								missing = true;
							}
							*/
							if ( $('#completar_registro_form [name="edo_cliente_direccion_dist"]').val() === -1 ) {
								missing = true;
							}
							if ( missing ) {
								$('.btn-siguiente-resumen').attr("disabled", true);
								$('.btn-submit-add-to-cart').attr("disabled", true);
							}
							else {
								$('.btn-siguiente-resumen').removeAttr("disabled");
								$('.btn-submit-add-to-cart').removeAttr("disabled");
							}
						} else {
							$('.btn-siguiente-resumen').removeAttr("disabled");
							$('.btn-submit-add-to-cart').removeAttr("disabled");
						}
					} else {
						var missing;
						if ( $select_cliente.val() === -1 ) {
							missing = false;
							if ( !$('#completar_registro_form [name="edo_cliente_nombre"]').val() ) {
								missing = true;
							}
							if ( !$('#completar_registro_form [name="edo_cliente_direccion_direcc"]').val() ) {
								missing = true;
							}
							/*
							if ( !$('#completar_registro_form [name="edo_cliente_direccion_refer"]').val() ) {
								missing = true;
							}
							*/
							if ( $('#completar_registro_form [name="edo_cliente_direccion_dist"]').val() === -1 ) {
								missing = true;
							}
							if ( missing ) {
								$('.btn-siguiente-resumen').attr("disabled", true);
								$('.btn-submit-add-to-cart').attr("disabled", true);
							}
							else {
								$('.btn-siguiente-resumen').removeAttr("disabled");
								$('.btn-submit-add-to-cart').removeAttr("disabled");
							}
						} else {
							missing = false;
							if ( $('#completar_registro_form [name="edo_cliente_direccion_dist"]').val() === -1 ) {
								missing = true;
							}
							if ( !$('#completar_registro_form [name="edo_cliente_direccion_direcc"]').val() ) {
								missing = true;
							}
							if ( missing ) {
								$('.btn-siguiente-resumen').attr("disabled", true);
								$('.btn-submit-add-to-cart').attr("disabled", true);
							}
							else {
								$('.btn-siguiente-resumen').removeAttr("disabled");
								$('.btn-submit-add-to-cart').removeAttr("disabled");
							}
						}
					}
					
				} else {
					$('.btn-siguiente-resumen').attr("disabled", true);
					$('.btn-submit-add-to-cart').attr("disabled", true);
				}
			},
			map: null,
			marker: null,
			polygons: [],
			mkl: {},
			setupMap: function () {
				var _this = this;
				var infoWindow;
				var center = new google.maps.LatLng(-12.108725097809442, -76.99580349999997);
				_this.map = new google.maps.Map(document.getElementById('map'), {
					center: center,
					zoom: 13,
					scaleControl: true
				});
				infoWindow = new google.maps.InfoWindow;
				var handleLocationError = function (browserHasGeolocation, infoWindow, pos) {
					infoWindow.setPosition(pos);
					infoWindow.setContent(browserHasGeolocation ?
						'Error: The Geolocation service failed.' :
						'Error: Your browser doesn\'t support geolocation.');
				};
				var codeLatLngCurrent = function (lat, lng, currentIndexPolygon) {
					var geocoder = new google.maps.Geocoder;
					geocoder.geocode({'location': {lat: lat, lng: lng}}, function(results, status) {
						if (status === 'OK' && results[0] && results[0].formatted_address ) {
							var encontrado = false;
							var distritos = [];
							$('#completar_registro_form [name="edo_cliente_direccion_dist"] option').each( function ( index ) {
								distritos.push( $(this).text() );
							} );
							results.forEach( function (rs, indexRd) {
								var rsC_ = false; 
								if ( !encontrado ) {
									rs.address_components.forEach( function (rsC, indexRd3) {
										if(!encontrado && distritos.indexOf(rsC.long_name.toUpperCase()) != -1 ){
											encontrado = rsC.long_name.toUpperCase();
										}
									} );
								}
							} );
							if ( encontrado ) {
								var option_id = $('#form_cliente_direccion [name="edo_cliente_direccion_dist"] option').filter( function () { 
									return $(this).html() === encontrado; 
								}).val();
								if ( option_id ) {
									$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').val(option_id);
									encontrado = true;
								} else {
									$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').val('-1');
									$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').change();
								}
							} else {
								$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').val('-1');
								$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').change();
							}
							
							//$('#form_cliente_direccion [name="edo_cliente_direccion_direcc_real"]').val( results[0].formatted_address );
							$('#form_cliente_direccion [name="edo_cliente_direccion_direcc"]').val( results[0].formatted_address );
							$('#completar_registro [name="edo_cliente_direccion_sede"]').val( _this.polygons[currentIndexPolygon].name );
							$('#form_cliente_direccion [name="edo_cliente_direccion_direcc"]').change();
							_this.enabled_submit_siguiente();
						} else {
							//window.alert('Geocoder failed due to: ' + status);
							//$('#form_cliente_direccion [name="edo_cliente_direccion_direcc_real"]').val('');
							$('#form_cliente_direccion [name="edo_cliente_direccion_direcc"]').val('');
							$('#completar_registro [name="edo_cliente_direccion_sede"]').val('');
							$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').val('-1');
							$('#form_cliente_direccion [name="edo_cliente_direccion_dist"]').change();
							$('#form_cliente_direccion [name="edo_cliente_direccion_direcc"]').change();
							_this.enabled_submit_siguiente();
						}
					});
					/*
					httpGetAsync('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyA9t5zcuR0-RW5zuxQnYVTIrcg8yRxm0Zw', function(responseText) {
						if (responseText && responseText.results && responseText.results[0] && responseText.results[0].formatted_address) {
						} else {
						}
					});
					*/
				};
				var codeLatLng = function (lat, lng, currentIndexPolygon) {
					var geocoder = new google.maps.Geocoder;
					geocoder.geocode({'location': {lat: lat, lng: lng}}, function(results, status) {
						if (status === 'OK' && results[0] && results[0].formatted_address ) {
							var encontrado = false;
							var distritos = [];
							$('#completar_registro [name="edo_cliente_direccion_dist"] option').each( function ( index ) {
								distritos.push( $(this).text() );
							} );
							results.forEach( function (rs, indexRd) {
								var rsC_ = false; 
								if ( !encontrado ) {
									rs.address_components.forEach( function (rsC, indexRd3) {
										if(!encontrado && distritos.indexOf(rsC.long_name.toUpperCase()) != -1 ){
											encontrado = rsC.long_name.toUpperCase();
										}
									} );
								}
							} );
							if ( encontrado ) {
								var option_id = $('#completar_registro [name="edo_cliente_direccion_dist"] option').filter( function () { 
									return $(this).html() === encontrado; 
								}).val();
								if ( option_id ) {
									$('#completar_registro [name="edo_cliente_direccion_dist"]').val(option_id);
									encontrado = true;
								} else {
									$('#completar_registro [name="edo_cliente_direccion_dist"]').val('-1');
									$('#completar_registro [name="edo_cliente_direccion_dist"]').change();
								}
							} else {
								$('#completar_registro [name="edo_cliente_direccion_dist"]').val('-1');
								$('#completar_registro [name="edo_cliente_direccion_dist"]').change();
							}
							
							//$('#completar_registro [name="edo_cliente_direccion_direcc_real"]').val( results[0].formatted_address );
							$('#completar_registro [name="edo_cliente_direccion_direcc"]').val( results[0].formatted_address );
							$('#completar_registro [name="edo_cliente_direccion_sede"]').val( _this.polygons[currentIndexPolygon].name );
							$('#completar_registro [name="edo_cliente_direccion_direcc"]').change();
							_this.enabled_submit_siguiente();
						} else {
							//window.alert('Geocoder failed due to: ' + status);
							console.log( 'codeLatLng' );
							alert('Dirección no disponible para delivery');
							//$('#completar_registro [name="edo_cliente_direccion_direcc_real"]').val('');
							$('#completar_registro [name="edo_cliente_direccion_direcc"]').val('');
							$('#completar_registro [name="edo_cliente_direccion_sede"]').val('');
							$('#completar_registro [name="edo_cliente_direccion_dist"]').val('-1');
							$('#completar_registro [name="edo_cliente_direccion_dist"]').change();
							$('#completar_registro [name="edo_cliente_direccion_direcc"]').change();
							_this.enabled_submit_siguiente();
						}
					});
					/*
					httpGetAsync('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyA9t5zcuR0-RW5zuxQnYVTIrcg8yRxm0Zw', function(responseText) {
						if (responseText && responseText.results && responseText.results[0] && responseText.results[0].formatted_address) {
						} else {
						}
					});
					*/
				};
				var getLocationCurrent = function () {
					var lat = _this.marker.getPosition().lat();
					var lng = _this.marker.getPosition().lng();

					var dentro = false;
					var currentIndexPolygon = -1;
					_this.polygons.forEach(function(pl, index) {
						if (google.maps.geometry.poly.containsLocation(_this.marker.getPosition(), pl.polygon)) {
							dentro = true;
							currentIndexPolygon = index;
							return true;
						}
					});
					if (dentro) {
						codeLatLngCurrent(lat, lng, currentIndexPolygon);
					} else {
						//$('#completar_registro [name="edo_cliente_direccion_direcc_real"]').val('');
						$('#completar_registro [name="edo_cliente_direccion_direcc"]').val('');
						$('#completar_registro [name="edo_cliente_direccion_sede"]').val('');
						$('#completar_registro [name="edo_cliente_direccion_dist"]').val('-1');
						$('#completar_registro [name="edo_cliente_direccion_dist"]').change();
						_this.enabled_submit_siguiente();
					}
				};
				var getLocation = function () {
					var lat = _this.marker.getPosition().lat();
					var lng = _this.marker.getPosition().lng();

					var dentro = false;
					var currentIndexPolygon = -1;
					_this.polygons.forEach(function(pl, index) {
						if (google.maps.geometry.poly.containsLocation(_this.marker.getPosition(), pl.polygon)) {
							dentro = true;
							currentIndexPolygon = index;
							return true;
						}
					});
					if (dentro) {
						codeLatLng(lat, lng, currentIndexPolygon);
					} else {
						console.log( 'getLocation' );
						alert('Dirección no disponible para delivery');
						//$('#completar_registro [name="edo_cliente_direccion_direcc_real"]').val('');
						$('#completar_registro [name="edo_cliente_direccion_direcc"]').val('');
						$('#completar_registro [name="edo_cliente_direccion_sede"]').val('');
						$('#completar_registro [name="edo_cliente_direccion_dist"]').val('-1');
						$('#completar_registro [name="edo_cliente_direccion_dist"]').change();
						_this.enabled_submit_siguiente();
					}
				};
				var httpGetAsync = function (theUrl, callback) {
					var xmlHttp = new XMLHttpRequest();
					xmlHttp.onreadystatechange = function() {
						if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
							callback(JSON.parse(xmlHttp.responseText));
						}
					};
					xmlHttp.open("GET", theUrl, true); // true for asynchronous 
					xmlHttp.send(null);
				};
				var getCoordinates = function (coordinadas) {
					var cds = [];
					coordinadas.forEach(function(coordinaaa) {
						cds.push({
							lat: coordinaaa[1],
							lng: coordinaaa[0]
						});
					});
					return cds;
				};
				var placeMarker = function (location) {
					if( _this.marker === null ){
						_this.marker = new google.maps.Marker({
							position: location, 
							draggable: true,
							map: _this.map
						});
						_this.map.setCenter(location);
						getLocation();
						_this.marker.addListener('dragend', getLocation);
					} else {
						_this.marker.setMap( _this.map );
						_this.marker.setPosition(location);
						_this.map.setCenter(location);
						getLocation();
					}
				};
				_this.mkl.features.forEach(function(state) {
					if (state.geometry.type === 'Polygon') {
						if (state.properties.name != 'FUERA DE ZONA') {
							var pl = new google.maps.Polygon({
								path: getCoordinates(state.geometry.coordinates[0]),
								geodesic: true,
								strokeColor: '#000',
								//strokeOpacity: 1.0,
								strokeOpacity: 0,
								strokeWeight: 4,
								fillColor: '#000',
								//fillOpacity: 0.35
								fillOpacity: 0
							});
							pl.setMap(_this.map);
							pl.addListener('click', function (event) {
								placeMarker(event.latLng);
							});
						}
					}
				});

				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function(position) {
						var pos = {
							lat: position.coords.latitude,
							lng: position.coords.longitude
						};
						_this.map.setCenter(pos);
						_this.marker = new google.maps.Marker({
							position: pos,
							draggable: true,
							map: _this.map,
							//animation: google.maps.Animation.DROP,
						});
						getLocationCurrent();
						_this.marker.addListener('dragend', getLocation);

					}, function() {
						//handleLocationError(true, infoWindow, map.getCenter());
					});
				} else {
					//handleLocationError(false, infoWindow, map.getCenter());
				}
				var input = document.getElementById('pac-input');
				_this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

				var autocomplete = new google.maps.places.Autocomplete(input);
				autocomplete.addListener('place_changed', function() {
					var place = autocomplete.getPlace();
					if (!place.geometry) {
						window.alert("No seleccionó una dirección.");
						return;
					}
					if( _this.marker === null ){
						_this.marker = new google.maps.Marker({
							position: place.geometry.location, 
							draggable: true,
							map: _this.map
						});
						_this.map.setCenter(place.geometry.location);
						getLocation();
						_this.marker.addListener('dragend', getLocation);
					} else {
						_this.marker.setMap( _this.map );
						_this.marker.setPosition(place.geometry.location);
						_this.map.setCenter(place.geometry.location);
						getLocation();
					}
				});
			},
			initMap: function (){
				var _this = this;
				//var map;
				//var marker;
				//var ctaLayer;
				//var polygons;
				_this.mkl = {
					"type": "FeatureCollection",
					"features": [{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.059972, -12.09778]
							},
							"properties": {
								"name": "MAGDALENA DEL MAR",
								"styleUrl": "#icon-503-A61B4A-labelson",
								"styleHash": "-49f23a2a",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "entre la cuadra 8 y 9 de Av. Ejército / Cruce de Juan de Aliaga con Félix Dibós"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.036047, -12.120192]
							},
							"properties": {
								"name": "MIRAFLORES",
								"styleUrl": "#icon-503-3F5BA9-labelson",
								"styleHash": "-7c346b8a",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "Altura de la cuadra 6 de Pardo / Vivanda de Pardo"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.0002923, -12.1004229]
							},
							"properties": {
								"name": "SAN BORJA",
								"styleUrl": "#icon-503-009D57-labelson",
								"styleHash": "7284c516",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "Av. San Borja Sur #663                                             Altura de la cuadra 30 de Aviación / Al lado del Hotel Britania. Teléfono: 225-0881"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.9748914, -12.0850322]
							},
							"properties": {
								"name": "JOCKEY PLAZA - SURCO",
								"styleUrl": "#icon-503-DB4436-labelson",
								"styleHash": "-511c140a",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "C.C. Jockey Plaza. Por puerta de Manuel Olguin. Por los cines.  Teléfono: 435-3658"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.9495984, -12.0910921]
							},
							"properties": {
								"name": "LA MOLINA",
								"styleUrl": "#icon-503-F4EB37-labelson",
								"styleHash": "-25d9bea",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "Las Caobas 180. Teléfono: 495-1999. Pasando Molina Plaza"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.0345882, -12.0936645]
							},
							"properties": {
								"name": "BASADRE - SAN ISIDRO",
								"styleUrl": "#icon-503-F4B400-labelson",
								"styleHash": "-13148d6a",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "Av. Jorge Basadre 275 - SAN ISIDRO "
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.9881508, -12.1264496]
							},
							"properties": {
								"name": "TRIGAL - SURCO",
								"styleUrl": "#icon-503-7C3592-labelson",
								"styleHash": "16fbb3d6",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "Calle Antares 320 Surco. Teléfono: 448-1237.           En el Centro Empresarial Nuevo El Trigal. / Cruce de Velasco Astete y Benavides"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0833558, -12.0914256],
										[-77.0819496, -12.0888181],
										[-77.078759, -12.0904749],
										[-77.0770268, -12.0913295],
										[-77.0767535, -12.0912953],
										[-77.0752893, -12.0918485],
										[-77.0721021, -12.0929574],
										[-77.0717143, -12.0931192],
										[-77.0711665, -12.0933205],
										[-77.0681236, -12.0850314],
										[-77.0673062, -12.0829246],
										[-77.0713087, -12.08145],
										[-77.0756606, -12.0798382],
										[-77.0753347, -12.0789347],
										[-77.074762, -12.0777059],
										[-77.0740284, -12.0761728],
										[-77.0730374, -12.0764338],
										[-77.0694673, -12.0775433],
										[-77.0664177, -12.0784062],
										[-77.0654923, -12.0785977],
										[-77.0645857, -12.07726],
										[-77.0617533, -12.0724759],
										[-77.0615816, -12.0715107],
										[-77.06038, -12.0715107],
										[-77.0574617, -12.0723081],
										[-77.0536852, -12.0752037],
										[-77.0486319, -12.0792796],
										[-77.0467382, -12.0795367],
										[-77.0467651, -12.0811366],
										[-77.0457458, -12.0813307],
										[-77.0458424, -12.0822329],
										[-77.0455447, -12.0824768],
										[-77.0459068, -12.0825844],
										[-77.048825, -12.0852255],
										[-77.0496243, -12.086125],
										[-77.0501983, -12.087148],
										[-77.050649, -12.0879505],
										[-77.0532775, -12.0930124],
										[-77.0507777, -12.0946699],
										[-77.0403707, -12.0932642],
										[-77.0401132, -12.0952941],
										[-77.0413792, -12.0977489],
										[-77.0419961, -12.0986406],
										[-77.0424011, -12.0990786],
										[-77.0432085, -12.0996739],
										[-77.046175, -12.1019241],
										[-77.0464325, -12.1027738],
										[-77.046926, -12.1028106],
										[-77.0459819, -12.10419],
										[-77.0402634, -12.1054594],
										[-77.0403626, -12.1059472],
										[-77.0401508, -12.1070145],
										[-77.0397095, -12.1074669],
										[-77.0413685, -12.1090863],
										[-77.0431173, -12.1107569],
										[-77.0435545, -12.1105208],
										[-77.0439917, -12.1104002],
										[-77.0444342, -12.1104081],
										[-77.0460784, -12.1104264],
										[-77.0461227, -12.1103805],
										[-77.0468402, -12.1109195],
										[-77.046529, -12.1112132],
										[-77.0483583, -12.112965],
										[-77.0490825, -12.1136101]
									]
								]
							},
							"properties": {
								"name": "MAGDALENA DEL MAR",
								"styleUrl": "#line-A61B4A-3325-nodesc",
								"styleHash": "-613ad45",
								"styleMapHash": {
									"normal": "#line-A61B4A-3325-nodesc-normal",
									"highlight": "#line-A61B4A-3325-nodesc-highlight"
								},
								"stroke": "#a61b4a",
								"stroke-opacity": 1,
								"stroke-width": 3.325
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0266286, -12.108794],
										[-77.0266048, -12.1090128],
										[-77.0265662, -12.1093063],
										[-77.0265802, -12.1093847],
										[-77.0265332, -12.1096856],
										[-77.0263855, -12.110492],
										[-77.0262966, -12.1110978],
										[-77.0262279, -12.1116945],
										[-77.0262396, -12.1118872],
										[-77.026251, -12.1121272],
										[-77.0262489, -12.1124262],
										[-77.0262824, -12.1126097],
										[-77.0263052, -12.1135498],
										[-77.0262972, -12.1147142],
										[-77.0263481, -12.1151312],
										[-77.0263682, -12.1161054],
										[-77.0263561, -12.1173813],
										[-77.0263253, -12.1177254],
										[-77.0262381, -12.1181981],
										[-77.0260369, -12.1192431],
										[-77.0251733, -12.1192825],
										[-77.0248515, -12.1194844],
										[-77.0241649, -12.1199486],
										[-77.0237841, -12.1198647],
										[-77.0181539, -12.1195028],
										[-77.0181808, -12.1185586],
										[-77.0172581, -12.1185587],
										[-77.0164319, -12.1185167],
										[-77.0159949, -12.1184801],
										[-77.0140154, -12.1174048],
										[-77.0138398, -12.1172882],
										[-77.0137391, -12.1172501],
										[-77.0137485, -12.1167689],
										[-77.0137767, -12.1160333],
										[-77.013067, -12.1159971],
										[-77.0126257, -12.1159504],
										[-77.0125449, -12.1159225],
										[-77.0124745, -12.1158773],
										[-77.0124195, -12.1158078],
										[-77.0123953, -12.1157435],
										[-77.0123926, -12.1156531],
										[-77.0124409, -12.1150656],
										[-77.0118025, -12.1149476],
										[-77.0117288, -12.1149253],
										[-77.0112205, -12.1147036],
										[-77.0110448, -12.1150288],
										[-77.0107109, -12.1153094],
										[-77.0105204, -12.1151993],
										[-77.0117167, -12.113477],
										[-77.0128781, -12.1117232],
										[-77.0135661, -12.1107198],
										[-77.0143024, -12.1096377],
										[-77.0147369, -12.1090627],
										[-77.014782, -12.109004],
										[-77.0153554, -12.1083133],
										[-77.0163079, -12.1072584],
										[-77.0179058, -12.105479],
										[-77.0184356, -12.1056705],
										[-77.0185241, -12.1057335],
										[-77.0190633, -12.1059698],
										[-77.0195838, -12.1061784],
										[-77.0205951, -12.1065444],
										[-77.0227451, -12.1073538],
										[-77.0265419, -12.1087877],
										[-77.0266286, -12.108794]
									]
								]
							},
							"properties": {
								"name": "FUERA DE ZONA",
								"styleUrl": "#poly-000000-1200-196-nodesc",
								"styleHash": "-4de8ec0b",
								"styleMapHash": {
									"normal": "#poly-000000-1200-196-nodesc-normal",
									"highlight": "#poly-000000-1200-196-nodesc-highlight"
								},
								"stroke": "#000000",
								"stroke-opacity": 1,
								"stroke-width": 1.2,
								"fill": "#000000",
								"fill-opacity": 0.7686274509803922
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0096272, -12.1347597],
										[-77.0084682, -12.1349862],
										[-77.0076423, -12.135217],
										[-77.0070415, -12.1354687],
										[-77.0062476, -12.1358988],
										[-77.0054859, -12.1363288],
										[-77.0049495, -12.1365701],
										[-77.0041448, -12.1370001],
										[-77.0039141, -12.137105],
										[-77.0036191, -12.1372414],
										[-77.003029, -12.1375455],
										[-77.0039302, -12.1390245],
										[-77.0044559, -12.139444],
										[-77.0046813, -12.1397063],
										[-77.0049066, -12.1400629],
										[-77.0053358, -12.1404719],
										[-77.0057648, -12.1413111],
										[-77.003898, -12.1423809],
										[-77.0025033, -12.1433774],
										[-77.001012, -12.1444262],
										[-77.0006472, -12.144615],
										[-77.0002287, -12.1440486],
										[-76.9977826, -12.145601],
										[-76.9973427, -12.1458212],
										[-76.99745, -12.1461149],
										[-76.9970316, -12.1463037],
										[-76.9960981, -12.146503],
										[-76.9960123, -12.1466289],
										[-76.996066, -12.1469645],
										[-76.995551, -12.1470589],
										[-76.995272, -12.1472896],
										[-76.9930619, -12.1438389],
										[-76.9917743, -12.1418565],
										[-76.9914095, -12.1416887],
										[-76.9909159, -12.1421607],
										[-76.9905941, -12.1423075],
										[-76.9902722, -12.1423704],
										[-76.9894675, -12.1424858],
										[-76.9884376, -12.142727],
										[-76.9878475, -12.1428319],
										[-76.985015, -12.1433774],
										[-76.9839636, -12.1435242],
										[-76.9817965, -12.1438808],
										[-76.9812815, -12.1441116],
										[-76.98067, -12.1406083],
										[-76.9801872, -12.1383532],
										[-76.9798009, -12.1367169],
										[-76.97964, -12.1362029],
										[-76.9793611, -12.1354477],
										[-76.9785993, -12.1335177],
										[-76.9780092, -12.133738],
										[-76.9773762, -12.1339478],
										[-76.9767754, -12.1339373],
										[-76.9764321, -12.1339583],
										[-76.9762497, -12.1341261],
										[-76.9754987, -12.1344932],
										[-76.9745545, -12.1349023],
										[-76.9736962, -12.135217],
										[-76.973074, -12.1352799],
										[-76.9721835, -12.1351855],
										[-76.9718401, -12.1351016],
										[-76.9718509, -12.134682],
										[-76.9718831, -12.1343673],
										[-76.9718723, -12.1340527],
										[-76.9717435, -12.1339897],
										[-76.9717006, -12.1338848],
										[-76.9707992, -12.1336856],
										[-76.9705525, -12.1338639],
										[-76.970531, -12.1337275],
										[-76.9704667, -12.1335282],
										[-76.970531, -12.1333289],
										[-76.9705739, -12.1331191],
										[-76.970531, -12.1329408],
										[-76.9704452, -12.132752],
										[-76.9703057, -12.1327205],
										[-76.9699731, -12.1326891],
										[-76.96978, -12.1325737],
										[-76.9696727, -12.1323849],
										[-76.9696191, -12.1321017],
										[-76.9694796, -12.1319968],
										[-76.9688144, -12.1319548],
										[-76.9685891, -12.1316926],
										[-76.9685247, -12.1315353],
										[-76.9684282, -12.1313465],
										[-76.9681492, -12.1312521],
										[-76.9677952, -12.13078],
										[-76.9671836, -12.13078],
										[-76.9667652, -12.1308954],
										[-76.9664541, -12.1310213],
										[-76.965982, -12.1311472],
										[-76.9660786, -12.1308849],
										[-76.9658103, -12.1307696],
										[-76.9656387, -12.1307696],
										[-76.9657031, -12.13035],
										[-76.9658962, -12.1295843],
										[-76.9661, -12.1289995],
										[-76.9662475, -12.128677],
										[-76.9664004, -12.1283938],
										[-76.96652, -12.1282419],
										[-76.9667572, -12.1280502],
										[-76.966894, -12.1279375],
										[-76.9670254, -12.1277827],
										[-76.9671245, -12.1275353],
										[-76.9672485, -12.1269087],
										[-76.9672909, -12.1266394],
										[-76.9673875, -12.1264689],
										[-76.9676343, -12.1264112],
										[-76.9678059, -12.126385],
										[-76.9678703, -12.1262906],
										[-76.9679561, -12.1258605],
										[-76.9680408, -12.1253973],
										[-76.9682994, -12.1254619],
										[-76.9686213, -12.1256088],
										[-76.9687393, -12.1255144],
										[-76.9693445, -12.1252925],
										[-76.9703165, -12.1253465],
										[-76.971046, -12.1247591],
										[-76.971368, -12.1245493],
										[-76.9717865, -12.1244025],
										[-76.9724731, -12.1244235],
										[-76.9748335, -12.1245074],
										[-76.9748442, -12.12392],
										[-76.9749193, -12.1235319],
										[-76.9750588, -12.1231333],
										[-76.975091, -12.1227976],
										[-76.9750052, -12.1224514],
										[-76.9745805, -12.1211762],
										[-76.9745535, -12.1207741],
										[-76.974812, -12.1202696],
										[-76.9751607, -12.1198658],
										[-76.9755631, -12.1200073],
										[-76.9758366, -12.120086],
										[-76.976872, -12.120107],
										[-76.9770544, -12.1173849],
										[-76.9773977, -12.1146156],
										[-76.9781004, -12.1102255],
										[-76.9815309, -12.110417],
										[-76.9843927, -12.1106556],
										[-76.989328, -12.111007],
										[-76.9939417, -12.1112483],
										[-77.0020634, -12.11182],
										[-77.0018864, -12.1143061],
										[-77.0023584, -12.1143166],
										[-77.0023745, -12.1140911],
										[-77.0027715, -12.114154],
										[-77.0037049, -12.1150667],
										[-77.0055181, -12.1169601],
										[-77.0058185, -12.1171384],
										[-77.0055824, -12.1173849],
										[-77.0063388, -12.117794],
										[-77.0069289, -12.1169024],
										[-77.0083663, -12.1176996],
										[-77.0054002, -12.1220319],
										[-77.0085275, -12.1240239],
										[-77.0089727, -12.1245955],
										[-77.0093372, -12.1268046],
										[-77.0095195, -12.1281577],
										[-77.0094535, -12.1286596],
										[-77.0092729, -12.1306647],
										[-77.0091498, -12.1322318],
										[-77.0091763, -12.1329304],
										[-77.0093912, -12.1338419],
										[-77.0096272, -12.1347597]
									]
								]
							},
							"properties": {
								"name": "EL TRIGAL",
								"styleUrl": "#poly-7C3592-1200-76-nodesc",
								"styleHash": "12f5ed97",
								"styleMapHash": {
									"normal": "#poly-7C3592-1200-76-nodesc-normal",
									"highlight": "#poly-7C3592-1200-76-nodesc-highlight"
								},
								"stroke": "#7c3592",
								"stroke-opacity": 1,
								"stroke-width": 1.2,
								"fill": "#7c3592",
								"fill-opacity": 0.2980392156862745
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-76.9664062, -12.1263082],
										[-76.966233, -12.1263964],
										[-76.9659524, -12.1262867],
										[-76.9646237, -12.125767],
										[-76.962478, -12.1248649],
										[-76.9620059, -12.1241516],
										[-76.9612119, -12.121718],
										[-76.9608901, -12.1206481],
										[-76.9601392, -12.1162004],
										[-76.9597749, -12.1152822],
										[-76.9590235, -12.1133891],
										[-76.9571351, -12.1112072],
										[-76.9534874, -12.1096547],
										[-76.95117, -12.1079763],
										[-76.9504835, -12.1065496],
										[-76.95117, -12.1047033],
										[-76.9530153, -12.1031507],
										[-76.954217, -12.1013464],
										[-76.9561479, -12.1005072],
										[-76.9579504, -12.1000875],
										[-76.9594527, -12.0986189],
										[-76.9600963, -12.0963949],
										[-76.9602249, -12.0908979],
										[-76.9607399, -12.0895969],
										[-76.9607399, -12.087121],
										[-76.9591952, -12.0839317],
										[-76.9586372, -12.0822112],
										[-76.9575641, -12.0805745],
										[-76.9565771, -12.0791897],
										[-76.9570922, -12.0773432],
										[-76.9576071, -12.076462],
										[-76.958122, -12.0760843],
										[-76.9573925, -12.0748253],
										[-76.9559334, -12.0740699],
										[-76.9552469, -12.0745315],
										[-76.9549465, -12.0752869],
										[-76.9543887, -12.0762102],
										[-76.9538736, -12.0772593],
										[-76.952157, -12.0787701],
										[-76.9513416, -12.0795674],
										[-76.9503977, -12.0796513],
										[-76.9467925, -12.0784343],
										[-76.9434881, -12.0774691],
										[-76.9407843, -12.0765459],
										[-76.9390678, -12.0760003],
										[-76.9393682, -12.0755807],
										[-76.9394112, -12.0741119],
										[-76.9395399, -12.0734404],
										[-76.9405269, -12.0724752],
										[-76.9410849, -12.0719296],
										[-76.9437456, -12.0671035],
										[-76.9461489, -12.0626549],
										[-76.9466209, -12.0626969],
										[-76.9479085, -12.0626969],
										[-76.9490242, -12.0626969],
										[-76.9508267, -12.0631585],
										[-76.9518136, -12.0634943],
										[-76.9525863, -12.0643756],
										[-76.9530153, -12.0649212],
										[-76.9536591, -12.0653408],
										[-76.9583367, -12.0678589],
										[-76.9609545, -12.0626129],
										[-76.9614266, -12.0629907],
										[-76.9649885, -12.0643756],
										[-76.9674775, -12.0655087],
										[-76.9681214, -12.0658025],
										[-76.9692372, -12.0666838],
										[-76.9704389, -12.0673133],
										[-76.9715976, -12.0677749],
										[-76.9724131, -12.0701251],
										[-76.9731856, -12.0721814],
										[-76.9740869, -12.073944],
										[-76.9750311, -12.07642],
										[-76.9759322, -12.0785183],
										[-76.9766189, -12.0796513],
										[-76.9777346, -12.0809522],
										[-76.9793223, -12.082379],
										[-76.9809533, -12.0838058],
										[-76.9820261, -12.0849599],
										[-76.9821978, -12.0853795],
										[-76.9821763, -12.0860719],
										[-76.9811893, -12.0891562],
										[-76.9809104, -12.0921147],
										[-76.9804812, -12.0950521],
										[-76.9798588, -12.0988182],
										[-76.9794191, -12.102065],
										[-76.9789361, -12.1054376],
										[-76.9782065, -12.110368],
										[-76.97752, -12.1146899],
										[-76.9773431, -12.1161174],
										[-76.977177, -12.1174592],
										[-76.9770051, -12.1201236],
										[-76.9761148, -12.1200816],
										[-76.9758224, -12.1200763],
										[-76.9756815, -12.1200422],
										[-76.9755139, -12.1200029],
										[-76.9750632, -12.1198404],
										[-76.9745911, -12.120753],
										[-76.9746181, -12.1211551],
										[-76.9747842, -12.1215817],
										[-76.9751224, -12.1225291],
										[-76.9752726, -12.1230326],
										[-76.9751867, -12.1232582],
										[-76.9751438, -12.1234837],
										[-76.9750793, -12.1236534],
										[-76.9749516, -12.1244889],
										[-76.9731747, -12.1244034],
										[-76.9720644, -12.1243771],
										[-76.9714861, -12.1245308],
										[-76.9710611, -12.1248334],
										[-76.9704925, -12.1253054],
										[-76.9699266, -12.1252792],
										[-76.9694626, -12.125274],
										[-76.9690334, -12.1254208],
										[-76.9688134, -12.125531],
										[-76.9687394, -12.1255903],
										[-76.968218, -12.1253998],
										[-76.9682018, -12.1253631],
										[-76.9672628, -12.1258719],
										[-76.9664062, -12.1263082]
									]
								]
							},
							"properties": {
								"name": "JOCKEY PLAZA",
								"styleUrl": "#poly-DB4436-1200-76-nodesc",
								"styleHash": "-753a1d57",
								"styleMapHash": {
									"normal": "#poly-DB4436-1200-76-nodesc-normal",
									"highlight": "#poly-DB4436-1200-76-nodesc-highlight"
								},
								"stroke": "#db4436",
								"stroke-opacity": 1,
								"stroke-width": 1.2,
								"fill": "#db4436",
								"fill-opacity": 0.2980392156862745
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-76.9434132, -12.1184156],
										[-76.9434146, -12.1184353],
										[-76.941698, -12.1186031],
										[-76.9389516, -12.1195262],
										[-76.9357758, -12.1191906],
										[-76.9330292, -12.1196941],
										[-76.9320577, -12.1178735],
										[-76.9309692, -12.1158338],
										[-76.9306259, -12.1102951],
										[-76.9309692, -12.1055955],
										[-76.9277076, -12.1045884],
										[-76.9235878, -12.10291],
										[-76.9212703, -12.1054277],
										[-76.9186955, -12.1063508],
										[-76.9178372, -12.1027421],
										[-76.9173222, -12.0983781],
										[-76.9192104, -12.0945176],
										[-76.9213561, -12.0921676],
										[-76.9186955, -12.0879713],
										[-76.9139747, -12.0885588],
										[-76.9095974, -12.0885588],
										[-76.9090824, -12.0922516],
										[-76.9038468, -12.0959443],
										[-76.8998985, -12.0978746],
										[-76.8966369, -12.0992174],
										[-76.8934611, -12.1005601],
										[-76.8914012, -12.1003923],
										[-76.8912295, -12.0982942],
										[-76.8927744, -12.0961961],
										[-76.892002, -12.0926712],
										[-76.8894271, -12.0919998],
										[-76.8871955, -12.0911605],
										[-76.8855647, -12.091748],
										[-76.883934, -12.089482],
										[-76.8851357, -12.0878034],
										[-76.8859939, -12.085957],
										[-76.8865089, -12.0841106],
										[-76.8893413, -12.0841106],
										[-76.8908004, -12.0833552],
										[-76.8904571, -12.0811731],
										[-76.8898563, -12.0785712],
										[-76.8903712, -12.0771444],
										[-76.8923453, -12.0751406],
										[-76.8931715, -12.0758671],
										[-76.8942766, -12.0766146],
										[-76.8947164, -12.0768113],
										[-76.8954674, -12.0770657],
										[-76.8960977, -12.0769096],
										[-76.8965349, -12.0769529],
										[-76.8968944, -12.0770919],
										[-76.8975703, -12.0776217],
										[-76.8976668, -12.0778578],
										[-76.8987505, -12.0783037],
										[-76.8995551, -12.0782775],
										[-76.9008427, -12.0777319],
										[-76.9018297, -12.07769],
										[-76.9021731, -12.0776637],
										[-76.9025219, -12.0776689],
										[-76.9032459, -12.07769],
										[-76.9039755, -12.0778998],
										[-76.9045763, -12.0778998],
										[-76.9068507, -12.0771444],
										[-76.9074999, -12.0769135],
										[-76.9074677, -12.0765726],
										[-76.9077306, -12.0760009],
										[-76.9089322, -12.074018],
										[-76.9105685, -12.0713584],
										[-76.9110727, -12.0710686],
										[-76.911593, -12.0710463],
										[-76.9120168, -12.0711446],
										[-76.912301, -12.0713584],
										[-76.9126068, -12.0716941],
										[-76.9127731, -12.0721085],
										[-76.9136773, -12.0744212],
										[-76.9138842, -12.0746881],
										[-76.9141125, -12.0748448],
										[-76.9153815, -12.0753058],
										[-76.915694, -12.0756074],
										[-76.9157826, -12.075917],
										[-76.9155627, -12.0764939],
										[-76.9156673, -12.0767431],
										[-76.9166732, -12.0771392],
										[-76.918438, -12.0780257],
										[-76.920455, -12.0790748],
										[-76.9222574, -12.08004],
										[-76.9228153, -12.080082],
										[-76.9229441, -12.0794945],
										[-76.9237595, -12.0795364],
										[-76.9248753, -12.0801239],
										[-76.9258623, -12.0797882],
										[-76.9256907, -12.0791168],
										[-76.9257336, -12.0784034],
										[-76.9266777, -12.077648],
										[-76.927493, -12.0768506],
										[-76.927493, -12.0761792],
										[-76.9281797, -12.0757595],
										[-76.9287376, -12.0756756],
										[-76.9291667, -12.0758435],
										[-76.9295959, -12.0755077],
										[-76.9295529, -12.0746265],
										[-76.9302397, -12.0753818],
										[-76.9306259, -12.0761792],
										[-76.931098, -12.0763471],
										[-76.9313555, -12.0767667],
										[-76.9322139, -12.0769765],
										[-76.9335013, -12.0769346],
										[-76.9346332, -12.0762684],
										[-76.9354243, -12.0757648],
										[-76.9361942, -12.0755877],
										[-76.9369774, -12.0755077],
										[-76.9372672, -12.0754946],
										[-76.9375783, -12.0755654],
										[-76.9381362, -12.0757595],
										[-76.9390589, -12.0759693],
										[-76.9415049, -12.0767562],
										[-76.9445089, -12.0777949],
										[-76.9499806, -12.0796204],
										[-76.9506673, -12.0797463],
										[-76.951311, -12.0797043],
										[-76.9521265, -12.078907],
										[-76.9531994, -12.0778578],
										[-76.953607, -12.077711],
										[-76.9544869, -12.0760533],
										[-76.954916, -12.0754238],
										[-76.9552164, -12.0746684],
										[-76.9559031, -12.0742068],
										[-76.9566922, -12.0745972],
										[-76.9570867, -12.0747924],
										[-76.9575456, -12.0751344],
										[-76.9577268, -12.0756336],
										[-76.9580916, -12.0762212],
										[-76.9575767, -12.0765989],
										[-76.9570617, -12.0774801],
										[-76.9565468, -12.0793266],
										[-76.9586066, -12.0823481],
										[-76.9591216, -12.0837749],
										[-76.9607096, -12.0872579],
										[-76.9607525, -12.0893141],
										[-76.9601645, -12.0910698],
										[-76.9601088, -12.0933006],
										[-76.960066, -12.0965318],
										[-76.959422, -12.0987558],
										[-76.95792, -12.1002244],
										[-76.9561176, -12.1006441],
										[-76.9541865, -12.1014833],
										[-76.9529848, -12.1032876],
										[-76.9511394, -12.1048402],
										[-76.9504527, -12.1066865],
										[-76.9488219, -12.1075257],
										[-76.9466763, -12.1092041],
										[-76.945818, -12.1107147],
										[-76.9460755, -12.1127288],
										[-76.9450455, -12.1136519],
										[-76.942728, -12.1144911],
										[-76.9420413, -12.1154142],
										[-76.9433288, -12.1171765],
										[-76.9434132, -12.1184156]
									]
								]
							},
							"properties": {
								"name": "LA MOLINA",
								"styleUrl": "#poly-F4EB37-1801-76-nodesc",
								"styleHash": "2e58b4cd",
								"styleMapHash": {
									"normal": "#poly-F4EB37-1801-76-nodesc-normal",
									"highlight": "#poly-F4EB37-1801-76-nodesc-highlight"
								},
								"stroke": "#f4eb37",
								"stroke-opacity": 1,
								"stroke-width": 1.801,
								"fill": "#f4eb37",
								"fill-opacity": 0.2980392156862745
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0430878, -12.0993002],
										[-77.0461133, -12.1014298],
										[-77.0463708, -12.1024159],
										[-77.0469287, -12.1024159],
										[-77.0461133, -12.1037796],
										[-77.0402016, -12.1051145],
										[-77.0403223, -12.1055945],
										[-77.0401105, -12.1066618],
										[-77.0396692, -12.1071142],
										[-77.0396599, -12.1071529],
										[-77.0396719, -12.1071994],
										[-77.0385333, -12.1082445],
										[-77.037168, -12.1095781],
										[-77.036936, -12.1094955],
										[-77.0366772, -12.1095755],
										[-77.0319941, -12.1036118],
										[-77.0315327, -12.1031922],
										[-77.0275309, -12.1025627],
										[-77.0274926, -12.1050908],
										[-77.0274328, -12.1057412],
										[-77.0273568, -12.1060861],
										[-77.0271412, -12.1066617],
										[-77.0266458, -12.1077816],
										[-77.0265883, -12.1084413],
										[-77.0265016, -12.108435],
										[-77.025645, -12.1081429],
										[-77.0183954, -12.1053178],
										[-77.0186607, -12.1025247],
										[-77.0190039, -12.1014023],
										[-77.0196501, -12.099801],
										[-77.0233889, -12.0894861],
										[-77.0299598, -12.0904171],
										[-77.0299648, -12.0907607],
										[-77.033657, -12.0903097],
										[-77.0338287, -12.0885368],
										[-77.0338931, -12.0882115],
										[-77.0339468, -12.0878391],
										[-77.0344778, -12.0841987],
										[-77.0355024, -12.0843089],
										[-77.0460811, -12.0857986],
										[-77.0462206, -12.0848544],
										[-77.046167, -12.0846341],
										[-77.0457378, -12.0828611],
										[-77.0456842, -12.0826513],
										[-77.0457164, -12.082368],
										[-77.0458666, -12.0822317],
										[-77.0472184, -12.0834591],
										[-77.0487848, -12.0848334],
										[-77.0495841, -12.0857723],
										[-77.0510808, -12.0884738],
										[-77.0532801, -12.0926177],
										[-77.0507804, -12.0942752],
										[-77.043989, -12.0933625],
										[-77.0403733, -12.0928695],
										[-77.0401158, -12.0947158],
										[-77.04003, -12.0949361],
										[-77.0405772, -12.0959537],
										[-77.0413712, -12.0975168],
										[-77.0418862, -12.0983036],
										[-77.0423475, -12.0987967],
										[-77.0430878, -12.0993002]
									]
								]
							},
							"properties": {
								"name": "BASADRE",
								"styleUrl": "#poly-F4B400-2201-133-nodesc",
								"styleHash": "4e791448",
								"styleMapHash": {
									"normal": "#poly-F4B400-2201-133-nodesc-normal",
									"highlight": "#poly-F4B400-2201-133-nodesc-highlight"
								},
								"stroke": "#f4b400",
								"stroke-opacity": 1,
								"stroke-width": 2.201,
								"fill": "#f4b400",
								"fill-opacity": 0.5215686274509804
							}
						},
						/*
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.0852387, -12.0769977]
							},
							"properties": {
								"name": "EDO SAN MIGUEL",
								"styleUrl": "#icon-503-4186F0-labelson-nodesc",
								"styleHash": "54053455",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0756793, -12.0798435],
										[-77.0753347, -12.0789347],
										[-77.0740525, -12.0761571],
										[-77.0710124, -12.077088],
										[-77.0693429, -12.0775574],
										[-77.0664599, -12.0784856],
										[-77.0654252, -12.0786475],
										[-77.0647051, -12.0774227],
										[-77.0636496, -12.0756863],
										[-77.0617533, -12.0724759],
										[-77.0615816, -12.0715107],
										[-77.0613, -12.0682662],
										[-77.0616312, -12.068173],
										[-77.061882, -12.067933],
										[-77.061996, -12.0676825],
										[-77.0620242, -12.067474],
										[-77.0644466, -12.0675219],
										[-77.06486, -12.0675724],
										[-77.0658638, -12.0675684],
										[-77.0674368, -12.0676025],
										[-77.0674181, -12.0672996],
										[-77.0672357, -12.0661153],
										[-77.0669567, -12.0645441],
										[-77.0664686, -12.0620733],
										[-77.0672035, -12.0619658],
										[-77.0677561, -12.0619369],
										[-77.0680726, -12.0619487],
										[-77.0684964, -12.061992],
										[-77.068947, -12.0621153],
										[-77.0694432, -12.0623553],
										[-77.069816, -12.0625901],
										[-77.0701432, -12.0628445],
										[-77.0706877, -12.0634425],
										[-77.0713395, -12.0642399],
										[-77.0718263, -12.0638687],
										[-77.0722649, -12.0635605],
										[-77.0726645, -12.0633743],
										[-77.0731124, -12.0632327],
										[-77.0735014, -12.0631855],
										[-77.0738715, -12.0631698],
										[-77.0743208, -12.0632308],
										[-77.0749578, -12.0633862],
										[-77.0780879, -12.0641377],
										[-77.0783723, -12.0642189],
										[-77.0820254, -12.0651055],
										[-77.0817612, -12.0666196],
										[-77.0817384, -12.0673258],
										[-77.081784, -12.0682976],
										[-77.0820308, -12.0693809],
										[-77.0830983, -12.0725808],
										[-77.0837474, -12.0743854],
										[-77.0840116, -12.0744903],
										[-77.0857832, -12.0740916],
										[-77.0863652, -12.0739185],
										[-77.0869553, -12.0736772],
										[-77.0866924, -12.0729244],
										[-77.0867622, -12.0728326],
										[-77.087717, -12.0725048],
										[-77.0880201, -12.0723605],
										[-77.0875145, -12.0714215],
										[-77.0874676, -12.0712484],
										[-77.0874703, -12.0710596],
										[-77.0875548, -12.0708576],
										[-77.0876983, -12.0706976],
										[-77.0878243, -12.0705979],
										[-77.0880805, -12.0705533],
										[-77.0884171, -12.0705402],
										[-77.0885217, -12.0705192],
										[-77.0887956, -12.0703717],
										[-77.0898796, -12.0696523],
										[-77.0900519, -12.0695566],
										[-77.0903107, -12.0694596],
										[-77.0911556, -12.0692235],
										[-77.0912019, -12.0690969],
										[-77.0911516, -12.0687396],
										[-77.0910832, -12.0683973],
										[-77.0910805, -12.068232],
										[-77.0911288, -12.0680589],
										[-77.091222, -12.0679087],
										[-77.0913581, -12.0677428],
										[-77.0915016, -12.0676524],
										[-77.0917484, -12.0675553],
										[-77.0929286, -12.0673114],
										[-77.0930868, -12.0672458],
										[-77.0932585, -12.0671297],
										[-77.0933926, -12.0669927],
										[-77.0936876, -12.0666504],
										[-77.0939559, -12.0664327],
										[-77.0941731, -12.0662753],
										[-77.0943153, -12.0661389],
										[-77.0970458, -12.0647356],
										[-77.0973945, -12.0642032],
										[-77.0991594, -12.0641822],
										[-77.101804, -12.0642871],
										[-77.1045077, -12.0645704],
										[-77.1042931, -12.0657553],
										[-77.1041214, -12.0673704],
										[-77.1042073, -12.0683763],
										[-77.1043253, -12.0687619],
										[-77.1049234, -12.0712746],
										[-77.1051192, -12.0716681],
										[-77.1053646, -12.0722097],
										[-77.106286, -12.0745559],
										[-77.1065086, -12.0750201],
										[-77.1076727, -12.0773544],
										[-77.1047035, -12.078999],
										[-77.1029788, -12.0798776],
										[-77.0996382, -12.0813057],
										[-77.0991889, -12.0815641],
										[-77.0964879, -12.0827417],
										[-77.0958817, -12.0830512],
										[-77.0952058, -12.081551],
										[-77.0944655, -12.0818342],
										[-77.0948839, -12.0828519],
										[-77.0942295, -12.0831141],
										[-77.0936072, -12.0829568],
										[-77.0930278, -12.0817923],
										[-77.092309, -12.082149],
										[-77.0926309, -12.0828099],
										[-77.0900828, -12.0840479],
										[-77.0891688, -12.0845134],
										[-77.0863291, -12.0857553],
										[-77.0830206, -12.0873158],
										[-77.0815426, -12.0880869],
										[-77.0819496, -12.0888181],
										[-77.079456, -12.0901079],
										[-77.0770268, -12.0913295],
										[-77.0767535, -12.0912953],
										[-77.0752506, -12.0918548],
										[-77.072085, -12.0929518],
										[-77.0711665, -12.0933205],
										[-77.0697221, -12.0893589],
										[-77.0727677, -12.0882646],
										[-77.0738661, -12.0912],
										[-77.0764558, -12.0902283],
										[-77.0765568, -12.0905255],
										[-77.0782509, -12.0896582],
										[-77.0766302, -12.0865736],
										[-77.0781443, -12.0858051],
										[-77.0775958, -12.0847482],
										[-77.0771572, -12.0838485],
										[-77.0762774, -12.0816309],
										[-77.0756793, -12.0798435]
									]
								]
							},
							"properties": {
								"name": "SAN MIGUEL",
								"styleUrl": "#poly-4186F0-1701-143-nodesc",
								"styleHash": "50b18f91",
								"styleMapHash": {
									"normal": "#poly-4186F0-1701-143-nodesc-normal",
									"highlight": "#poly-4186F0-1701-143-nodesc-highlight"
								},
								"stroke": "#4186f0",
								"stroke-opacity": 1,
								"stroke-width": 1.701,
								"fill": "#4186f0",
								"fill-opacity": 0.5607843137254902
							}
						},
						*/
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-76.978413, -12.1101992],
										[-76.9785097, -12.1090075],
										[-76.9787779, -12.1069514],
										[-76.9790891, -12.1050212],
										[-76.9793681, -12.103154],
										[-76.9796685, -12.1003425],
										[-76.9802692, -12.0969015],
										[-76.9806555, -12.0942474],
										[-76.9808163, -12.0933976],
										[-76.9809046, -12.0927788],
										[-76.9810385, -12.0916849],
										[-76.9811061, -12.0910268],
										[-76.9812562, -12.0895161],
										[-76.9813796, -12.0885876],
										[-76.9816317, -12.0876277],
										[-76.9819692, -12.0867414],
										[-76.9821141, -12.0863007],
										[-76.9822218, -12.0859911],
										[-76.9822701, -12.0855715],
										[-76.9822777, -12.0851753],
										[-76.98229, -12.0849092],
										[-76.9825866, -12.0849263],
										[-76.98308, -12.0849106],
										[-76.98418, -12.0849105],
										[-76.9844374, -12.0839296],
										[-76.9846144, -12.0835021],
										[-76.9849256, -12.0831218],
										[-76.9851025, -12.0829094],
										[-76.9852901, -12.0826603],
										[-76.9855316, -12.0823297],
										[-76.9857569, -12.082057],
										[-76.9868567, -12.0806931],
										[-76.9873932, -12.0800216],
										[-76.9883267, -12.0807115],
										[-76.9888952, -12.0811206],
										[-76.9893404, -12.0814537],
										[-76.9895898, -12.0816635],
										[-76.9899291, -12.081691],
										[-76.9902657, -12.0817002],
										[-76.9909256, -12.0817134],
										[-76.9914728, -12.0817212],
										[-76.9918644, -12.0817134],
										[-76.9923875, -12.0817028],
										[-76.9928032, -12.0817212],
										[-76.9929104, -12.0813933],
										[-76.993471, -12.0817028],
										[-76.9935515, -12.081236],
										[-76.9945386, -12.081438],
										[-76.9956115, -12.0816792],
										[-76.9965557, -12.0822248],
										[-76.997575, -12.0828333],
										[-76.998369, -12.0833027],
										[-76.9993828, -12.0835886],
										[-77.0018156, -12.0839552],
										[-77.0077995, -12.0847205],
										[-77.0102285, -12.0850534],
										[-77.0108431, -12.0849388],
										[-77.0173194, -12.0820278],
										[-77.0186264, -12.081548],
										[-77.0192145, -12.0813619],
										[-77.0198596, -12.0813469],
										[-77.0208956, -12.0814548],
										[-77.0263841, -12.0825068],
										[-77.0231529, -12.0903029],
										[-77.0196959, -12.0996356],
										[-77.018599, -12.1028838],
										[-77.0183616, -12.1054888],
										[-77.0178039, -12.1054854],
										[-77.0143159, -12.1094167],
										[-77.0116845, -12.1133319],
										[-77.0104185, -12.1152057],
										[-77.0102211, -12.1150667],
										[-77.0083449, -12.1177086],
										[-77.0069074, -12.1169114],
										[-77.0063173, -12.117803],
										[-77.0055609, -12.1173939],
										[-77.005797, -12.1171474],
										[-77.00275, -12.114163],
										[-77.002353, -12.1141001],
										[-77.0023369, -12.1143256],
										[-77.0018649, -12.1143151],
										[-77.0021211, -12.1117742],
										[-77.0020419, -12.111829],
										[-76.9974354, -12.1114778],
										[-76.9928755, -12.1111999],
										[-76.9868621, -12.11088],
										[-76.9839144, -12.1106309],
										[-76.9814172, -12.1103817],
										[-76.978413, -12.1101992]
									]
								]
							},
							"properties": {
								"name": "SAN BORJA",
								"styleUrl": "#poly-009D57-1200-143-nodesc",
								"styleHash": "717a9d29",
								"styleMapHash": {
									"normal": "#poly-009D57-1200-143-nodesc-normal",
									"highlight": "#poly-009D57-1200-143-nodesc-highlight"
								},
								"stroke": "#009d57",
								"stroke-opacity": 1,
								"stroke-width": 1.2,
								"fill": "#009d57",
								"fill-opacity": 0.5607843137254902
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0673062, -12.0829246],
										[-77.0756606, -12.0798382],
										[-77.0763191, -12.0816866],
										[-77.077235, -12.0840176],
										[-77.0781443, -12.0858051],
										[-77.0766302, -12.0865736],
										[-77.0774496, -12.0881544],
										[-77.0782509, -12.0896582],
										[-77.0765568, -12.0905255],
										[-77.0764558, -12.0902283],
										[-77.0738661, -12.0912],
										[-77.0727677, -12.0882646],
										[-77.0697221, -12.0893589],
										[-77.0673062, -12.0829246]
									]
								]
							},
							"properties": {
								"name": "FUERA DE ZONA",
								"styleUrl": "#poly-000000-1200-145-nodesc",
								"styleHash": "-acb39b6",
								"styleMapHash": {
									"normal": "#poly-000000-1200-145-nodesc-normal",
									"highlight": "#poly-000000-1200-145-nodesc-highlight"
								},
								"stroke": "#000000",
								"stroke-opacity": 1,
								"stroke-width": 1.2,
								"fill": "#000000",
								"fill-opacity": 0.5686274509803921
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "LineString",
								"coordinates": [
									[-76.9218492, -12.0773859],
									[-76.9182873, -12.0890101]
								]
							},
							"properties": {
								"name": "AUMENTAR TIEMPO",
								"styleUrl": "#line-777777-3713-nodesc",
								"styleHash": "42fe105b",
								"styleMapHash": {
									"normal": "#line-777777-3713-nodesc-normal",
									"highlight": "#line-777777-3713-nodesc-highlight"
								},
								"stroke": "#777777",
								"stroke-opacity": 1,
								"stroke-width": 3.713
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.9719341, -12.1176068]
							},
							"properties": {
								"name": "La Tranquera - Casuarinas",
								"styleUrl": "#icon-962-62AF44-labelson-nodesc",
								"styleHash": "93200b3",
								"icon": "http://www.gstatic.com/mapspro/images/stock/962-wht-diamond-blank.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.9284046, -12.0828571]
							},
							"properties": {
								"name": "MOLICENTRO",
								"styleUrl": "#icon-962-62AF44-labelson-nodesc",
								"styleHash": "93200b3",
								"icon": "http://www.gstatic.com/mapspro/images/stock/962-wht-diamond-blank.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.900295, -12.0856897]
							},
							"properties": {
								"name": "CASINO DE LA POLICIA",
								"styleUrl": "#icon-962-62AF44-labelson-nodesc",
								"styleHash": "93200b3",
								"icon": "http://www.gstatic.com/mapspro/images/stock/962-wht-diamond-blank.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.8967867, -12.0771551]
							},
							"properties": {
								"name": "Tranquera La Planicie",
								"styleUrl": "#icon-962-62AF44-labelson-nodesc",
								"styleHash": "93200b3",
								"icon": "http://www.gstatic.com/mapspro/images/stock/962-wht-diamond-blank.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-76.9881105, -12.1014101]
							},
							"properties": {
								"name": "PENTAGONITO",
								"styleUrl": "#icon-959-62AF44-labelson-nodesc",
								"styleHash": "-66fe395",
								"icon": "http://www.gstatic.com/mapspro/images/stock/959-wht-circle-blank.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[-77.0240736, -12.1529026],
										[-77.0228022, -12.152782],
										[-77.022593, -12.1527662],
										[-77.0225233, -12.152546],
										[-77.0223302, -12.1526037],
										[-77.0197928, -12.1513346],
										[-77.020002, -12.1502385],
										[-77.0200771, -12.1497927],
										[-77.0200342, -12.1491215],
										[-77.0199645, -12.1478314],
										[-77.0194227, -12.1463682],
										[-77.0190203, -12.1454347],
										[-77.0187467, -12.1446428],
										[-77.0179474, -12.1425189],
										[-77.0178241, -12.1420783],
										[-77.0176685, -12.1416221],
										[-77.0175934, -12.1412654],
										[-77.0176202, -12.1403057],
										[-77.0176417, -12.1388687],
										[-77.0177007, -12.1380663],
										[-77.0177436, -12.1359371],
										[-77.017808, -12.133834],
										[-77.0147234, -12.1338183],
										[-77.0137256, -12.1338497],
										[-77.0128083, -12.1339966],
										[-77.0118427, -12.1342168],
										[-77.0097077, -12.1347623],
										[-77.0094717, -12.1338445],
										[-77.0093161, -12.1332518],
										[-77.009241, -12.1327379],
										[-77.0092303, -12.1322344],
										[-77.0093215, -12.1310124],
										[-77.0095575, -12.1288831],
										[-77.0096219, -12.1282694],
										[-77.0095199, -12.1274722],
										[-77.0091069, -12.1250072],
										[-77.0090532, -12.1245981],
										[-77.008608, -12.1240265],
										[-77.0082915, -12.1238009],
										[-77.0077926, -12.1234705],
										[-77.0066822, -12.1227467],
										[-77.0055556, -12.1220072],
										[-77.007342, -12.1193428],
										[-77.0084149, -12.1177746],
										[-77.0099008, -12.1156295],
										[-77.010323, -12.1150603],
										[-77.0107109, -12.1153094],
										[-77.0110448, -12.1150288],
										[-77.0112205, -12.1147036],
										[-77.0117676, -12.1149371],
										[-77.0124409, -12.1150656],
										[-77.0123953, -12.1157435],
										[-77.0124195, -12.1158078],
										[-77.0124745, -12.1158773],
										[-77.0126257, -12.1159504],
										[-77.0138329, -12.1160281],
										[-77.0137218, -12.1171833],
										[-77.0145733, -12.1177301],
										[-77.0159949, -12.1184801],
										[-77.0172581, -12.1185587],
										[-77.0181808, -12.1185586],
										[-77.0181539, -12.1195028],
										[-77.0237841, -12.1198647],
										[-77.0241649, -12.1199486],
										[-77.0251733, -12.1192825],
										[-77.0260369, -12.1192431],
										[-77.0263253, -12.1177254],
										[-77.0263588, -12.1172816],
										[-77.0263535, -12.1150892],
										[-77.0262972, -12.1147142],
										[-77.0262837, -12.1125402],
										[-77.0262489, -12.1124262],
										[-77.0262279, -12.1116945],
										[-77.0264179, -12.1102848],
										[-77.0265802, -12.1093847],
										[-77.0265949, -12.1092725],
										[-77.0266286, -12.108794],
										[-77.0266861, -12.1081343],
										[-77.0274731, -12.1060939],
										[-77.0275605, -12.1044208],
										[-77.0275696, -12.1039015],
										[-77.0275712, -12.1029154],
										[-77.0315784, -12.1035921],
										[-77.031675, -12.103613],
										[-77.0322973, -12.1042949],
										[-77.0341909, -12.1067706],
										[-77.0366693, -12.1099544],
										[-77.0369763, -12.1098482],
										[-77.0372083, -12.1099308],
										[-77.0396948, -12.1075364],
										[-77.0431173, -12.1107569],
										[-77.0435947, -12.1105208],
										[-77.0439917, -12.1104002],
										[-77.0456654, -12.1104265],
										[-77.0460784, -12.1104264],
										[-77.0461428, -12.1103845],
										[-77.0468402, -12.1109195],
										[-77.046529, -12.1112132],
										[-77.0483905, -12.1129545],
										[-77.0486051, -12.1132037],
										[-77.0488009, -12.1133688],
										[-77.0490825, -12.1136101]
									]
								]
							},
							"properties": {
								"name": "MIRAFLORES",
								"styleUrl": "#line-3F5BA9-2938-nodesc",
								"styleHash": "b43679d",
								"styleMapHash": {
									"normal": "#line-3F5BA9-2938-nodesc-normal",
									"highlight": "#line-3F5BA9-2938-nodesc-highlight"
								},
								"stroke": "#3f5ba9",
								"stroke-opacity": 1,
								"stroke-width": 2.938
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.0032167, -12.1322711]
							},
							"properties": {
								"name": "Punto 25",
								"styleUrl": "#icon-503-DB4436-labelson-nodesc",
								"styleHash": "4201b2bd",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
							}
						},
						{
							"type": "Feature",
							"geometry": {
								"type": "Point",
								"coordinates": [-77.0212513, -12.1555051]
							},
							"properties": {
								"name": "Independencia 276",
								"styleUrl": "#icon-503-DB4436-labelson",
								"styleHash": "-511c140a",
								"icon": "http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
								"description": "pasaje chumpitaz"
							}
						}
					]
				};
				_this.polygons = [];
				var getCoordinates = function (coordinadas) {
					var cds = [];
					coordinadas.forEach(function(coordinaaa) {
						cds.push({
							lat: coordinaaa[1],
							lng: coordinaaa[0]
						});
					});
					return cds;
				};
				_this.mkl.features.forEach(function(state) {
					if (state.geometry.type === 'Polygon') {
						if (state.properties.name != 'FUERA DE ZONA') {
							var pl = new google.maps.Polygon({
								path: getCoordinates(state.geometry.coordinates[0]),
								geodesic: true,
								strokeColor: '#000',
								//strokeOpacity: 1.0,
								strokeOpacity: 0,
								strokeWeight: 4,
								fillColor: '#000',
								//fillOpacity: 0.35
								fillOpacity: 0
							});
							_this.polygons.push({
								polygon: pl,
								name: state.properties.name
							});
						}
					}
				});
			},
			selectDirecctionDB: function () {
				var _this = this;
				var httpGetAsync = function (theUrl, callback) {
					var xmlHttp = new XMLHttpRequest();
					xmlHttp.onreadystatechange = function() {
						if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
							callback(JSON.parse(xmlHttp.responseText));
						}
					};
					xmlHttp.open("GET", theUrl, true); // true for asynchronous 
					xmlHttp.send(null);
				};
				if ( $('#clietes_direcciones .form_cliente_direcciones input[type="radio"]:checked').length ) {
					var direccionText = $('#clietes_direcciones .form_cliente_direcciones input[type="radio"]:checked').data('cliente-direccion');

					var sedeLocal = $('#clietes_direcciones .form_cliente_direcciones input[type="radio"]:checked').data('sede');
					var locales = {
						'008': 'BASADRE',
						'007': 'EL TRIGAL',
						'005': 'JOCKEY PLAZA',
						'002': 'LA MOLINA',
						'004': 'MAGDALENA DEL MAR',
						'001': 'MIRAFLORES',
						'006': 'SAN BORJA',
						'009': 'SAN MIGUEL',
					};
					if ( sedeLocal && sedeLocal != '' && locales[sedeLocal] != undefined && locales[sedeLocal] != '' ) {
						$('#completar_registro [name="edo_cliente_direccion_sede"]').val( locales[sedeLocal] );
					} else {
						var geocoder = new google.maps.Geocoder;
						geocoder.geocode({'address': direccionText }, function(results, status) {
							if (status === 'OK' && results[0] && results[0].formatted_address ) {
								var encontrado = false;
								var dentro = false;
								var currentIndexPolygon = -1;
								_this.polygons.forEach(function(pl, index) {
									var location = {
										lng: function () {
											return results[0].geometry.location.lng;
										},
										lat: function () {
											return results[0].geometry.location.lat;
										}
									};
									if (google.maps.geometry.poly.containsLocation(location, pl.polygon)) {
										dentro = true;
										currentIndexPolygon = index;
										return true;
									}
								});
								if (dentro) {
									$('#completar_registro [name="edo_cliente_direccion_sede"]').val( _this.polygons[currentIndexPolygon].name );
								} else {
									$('#completar_registro [name="edo_cliente_direccion_sede"]').val('');
									console.log( 'selectDirecctionDB' );
									alert('Dirección no disponible para delivery');
								}
								_this.enabled_submit_siguiente();
								return;
							} else {
								$('#completar_registro [name="edo_cliente_direccion_sede"]').val('');
								console.log( 'selectDirecctionDB 2' );
								alert('Dirección no disponible para delivery');
								_this.enabled_submit_siguiente();
							}
						});
						/*
						httpGetAsync('https://maps.googleapis.com/maps/api/geocode/json?address=' + direccionText + '&key=AIzaSyA9t5zcuR0-RW5zuxQnYVTIrcg8yRxm0Zw', function(responseText) {
							if (responseText && responseText.results && responseText.results[0] && responseText.results[0].formatted_address) {
							} else {
							}
						});
						*/
					}
					//return;
				}
			},
			clietes_direcciones_fun: function ( result ){
				var _this = this;

				$('#clietes_direcciones .form_cliente_direcciones input[type="radio"]').change( function () {
					var $element = $(this);
					var index_cliente = $element.data('index-cliente');
					if ( index_cliente === -1 ) {
						var source_direc	 = $("#cliente_direccion_form").html();
						var template_direc = Handlebars.compile(source_direc);
						var html_direc = template_direc({
						});
						$('#form_cliente_direccion').html( html_direc );
						_this.setupMap();
						$('#completar_registro_form [name="edo_cliente_direccion_direcc"]').change( function () {
							_this.enabled_submit();
						} );
						$('#completar_registro_form [name="edo_cliente_direccion_refer"]').change( function () {
							_this.enabled_submit();
						} );
						$('#completar_registro_form [name="edo_cliente_direccion_dist"]').change( function () {
							_this.enabled_submit();
						} );
					}
					else {
						$('#form_cliente_direccion').html( '' );
						_this.selectDirecctionDB();
					}
					_this.enabled_submit_siguiente();
				} );
				$('#clietes_direcciones .form_clientes input[type="radio"]').change( function () {
					var $element = $(this);
					var index_cliente = $element.data('index-cliente');
					$('#clietes_direcciones .form_cliente_direcciones .form-check[data-cliente]').fadeOut(0);
					$('#clietes_direcciones .form_cliente_direcciones .form-check[data-cliente] .form-check-input').prop('checked', false);
					$('#clietes_direcciones .form_cliente_direcciones .form-check[data-cliente] .form-check-input').prop('checked', false);
					if ( !$(this).is(':checked') ) {
						//$('#clietes_direcciones .form_cliente_direcciones .form-check[data-cliente="'+$element.val()+'"] .form-check-input').prop('checked', false);
					}
					else {
						$('#clietes_direcciones .form_cliente_direcciones .form-check[data-cliente="'+$element.val()+'"]').fadeIn(0);
					}
					if ( index_cliente === -1 ) {
						$('#clietes_direcciones .form_cliente_direcciones').fadeOut(0);
						var source_direc	 = $("#cliente_register").html();
						var template_direc = Handlebars.compile(source_direc);
						var $user_type_telephone = $('[name="edo_cliente_type_telephone"]');
						var html_direc = template_direc({
							edo_cliente_fijo: ( $user_type_telephone.length && $user_type_telephone.val() == 'fijo' ) ? $('[name="edo_cliente_telephone"]').val() : '',
							edo_cliente_celular: ( $user_type_telephone.length && $user_type_telephone.val() == 'celular' ) ? $('[name="edo_cliente_telephone"]').val() : '',
							edo_cliente_nombre: ( user_data && user_data.social_data && user_data.social_data.data ) ? user_data.social_data.data.display_name :'',
						});
						$('#form_cliente_direccion').html( html_direc );
						_this.setupMap();
						$('#completar_registro_form [name="edo_cliente_nombre"]').change( function () {
							_this.enabled_submit();
						} );
						$('#completar_registro_form [name="edo_cliente_direccion_direcc"]').change( function () {
							_this.enabled_submit();
						} );
						$('#completar_registro_form [name="edo_cliente_direccion_refer"]').change( function () {
							_this.enabled_submit();
						} );
						$('#completar_registro_form [name="edo_cliente_direccion_dist"]').change( function () {
							_this.enabled_submit();
						} );
						$('#completar_registro_form [name="edo_cliente_alergico"]').change( function () {
							var val = $(this).val();
							if ( val === 'si' ) {
								$('#completar_registro_form .form-group-alergias').fadeIn(0);
							}
							if ( val === 'no' ) {
								$('#completar_registro_form .form-group-alergias').fadeOut(0);
							}
						} );
					} else {
						if ( $('#clietes_direcciones .form_cliente_direcciones .form-check[data-cliente="'+$element.val()+'"]').length < 1 ) {
							$('#clietes_direcciones .form_cliente_direcciones').fadeOut(0);
							var source_direc	 = $("#cliente_direccion_form").html();
							var template_direc = Handlebars.compile(source_direc);
							var html_direc = template_direc({
							});
							$('#form_cliente_direccion').html( html_direc );
							_this.setupMap();


							$('#completar_registro_form [name="edo_cliente_direccion_direcc"]').change( function () {
								_this.enabled_submit();
							} );
							$('#completar_registro_form [name="edo_cliente_direccion_refer"]').change( function () {
								_this.enabled_submit();
							} );
							$('#completar_registro_form [name="edo_cliente_direccion_dist"]').change( function () {
								_this.enabled_submit();
							} );
						}
						else {
							$('#clietes_direcciones .form_cliente_direcciones').fadeIn(0);
							$('#form_cliente_direccion').html( '' );

						}
					}
					_this.enabled_submit_siguiente();
				} );

				$('.remove_address').click( function ( e ) {
					e.preventDefault();
					var $this = $(this);
					if( confirm( '¿Seguro que desea borrar esta dirección?' ) ) {
						var cliente = $this.data('cliente');
						var direccion_id = $this.data('direccion-id');
						var data = {
							'action': 'remove_direccion_cliente',
							'nIdDireccion': direccion_id,
						};
						jQuery.post(ajax_object.ajax_url, data, function(response) {
							if ( response.type === 'success' ) {
								$this.parent().parent().remove();
								$('.form_clientes .form-check-input').change();
								var $select_cliente = $('#clietes_direcciones .form_clientes input[type="radio"]:checked');
								var direcciones = $select_cliente.data('direcciones');
								if ( direcciones.length ) {
									for (var i = 0; i < direcciones.length; i++) {
										if ( direcciones[i].id === direccion_id ) {
												direcciones.splice(i, 1);
												direcciones.sort();
												$select_cliente.attr('data-direcciones', direcciones);
											break;
										}
									}
								}
							}
							else {
								$this.after('<span style="color: red; margin-left: 1em; float: right; ">Ocurrió un error.</span>');
								setTimeout( function () {
									$this.next().remove();
								}, 2000 );
							}
						});
					}
				} );
				_this.enabled_submit_siguiente();
			},
			open_completar_registro: function () {
				var _this = this;
				$.magnificPopup.open({
					items: {
						src: '#completar_registro',
						type: 'inline'
					},
					type:'inline',
					mainClass:'openAyuda',
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
					closeOnContentClick: false,
					closeOnBgClick: false,
					closeBtnInside: true,
					showCloseBtn: true,
					callbacks: {
						open: function () {
							if ( site_params.is_user_logged_in ) {
								if ( user_data && user_data.edo_cliente_data ) {
									$('.btn-siguiente-resumen').fadeIn(0);
									$('.btn-submit-add-to-cart').fadeIn(0);
									$('#completar_registro').addClass('second');
									$('.col-telefono-tipo').removeClass('col-lg-12').addClass('col-lg-6');
									_this.selectDirecctionDB();
								}
								_this.initMap();
								_this.enabled_submit_siguiente();
								_this.clietes_direcciones_fun( );
							}
						}
					}
				});
			},
			init: function() {
				// JavaScript to be fired on all pages
				var _this = this;
				var $this_btn = false;
				var open_completar = window.site_params.open_completar ? true : false;
				$('[data-toggle="tooltip"]').tooltip();

				if ( typeof Cookies.get( 'add_cart_edo') != 'undefined' ) {
					var data = JSON.parse( Cookies.get('add_cart_edo') );
					if ( data && data['add-to-cart'] ) {
						if ( $('[name="add-to-cart"]').length ) {
							if ( $('[name="add-to-cart"]').val() === data['add-to-cart'] ) {
								$.map( data, function(item, index) {
									if ( index != 'add-to-cart' && index != 'cart_item_key' ) {
										if ( $('[name="'+index+'"]').length ) {
											$('[name="'+index+'"]').val( item );
										}
									}
								}	);
								Cookies.remove( 'add_cart_edo');
								$this_btn = $('body .single_add_to_cart_button_package');
							}
						}
					}
				}
				$('body .single_add_to_cart_button_package').click( function ( e ) {
					$this_btn = $(this);
					if ( site_params.is_user_logged_in === '1' ) {
						var alert_text = '';
						if ( product_data && product_data.ebp_bundle_data_categories && product_data.ebp_bundle_data_category_product ) {
							Object.keys(product_data.ebp_bundle_data_categories).map(function(categoryKey, index) {
								var category_id = product_data.ebp_bundle_data_categories[categoryKey];
								var category = product_data.ebp_bundle_data_category_product[ category_id ];
								var piezas_cortes = parseFloat( category['piezas_cortes'] );
								var actual_piezas_cortes = 0;
								Object.keys(category).map(function(productKey, index) {
										var producto = category[productKey];
										if ( producto.show && producto.show == 'on' ) {
											var actual_value = $('[name="_category_package_product['+ category_id +']['+ productKey +'][quantity]"]').val();
											var step = parseFloat( producto.step );
											actual_value = parseFloat( actual_value );
											actual_piezas_cortes += actual_value / step;
										}
								} );
								if ( piezas_cortes != actual_piezas_cortes ) {
									if ( piezas_cortes > actual_piezas_cortes ) {
										if ( category.name === 'MAKIS' ) {
											alert_text+= 'Seleccionaste ' + actual_piezas_cortes + ' tablas de ' + category.name + ', te faltan ' + ( piezas_cortes - actual_piezas_cortes ) + ' \n';
										} else {
											alert_text+= 'Seleccionaste ' + actual_piezas_cortes + ' piezas/cortes de ' + category.name + ', te faltan ' + ( piezas_cortes - actual_piezas_cortes ) + ' \n';
										}
									}
									if ( piezas_cortes < actual_piezas_cortes ) {
										if ( category.name === 'MAKIS' ) {
											alert_text+= 'Seleccionaste ' + actual_piezas_cortes + ' tablas de ' + category.name + ', solo debes elegir ' + piezas_cortes + ' \n';
										} else {
											alert_text+= 'Seleccionaste ' + actual_piezas_cortes + ' piezas/cortes de ' + category.name + ', solo debes elegir ' + piezas_cortes + ' \n';
										}
									}
								}
							} );
							if ( alert_text ) {
								e.preventDefault();
								alert( alert_text );
							}
							else {
								if ( !open_completar ) {
									if ( site_params.open_complete_data != '1' ) {
										e.preventDefault();
										_this.open_completar_registro();
									}
								}
							}
						}
					}
					else {
						e.preventDefault();
						var indexed_array = {};
						var unindexed_array = $('.woocommerce div.product form').serializeArray();
						$.map(unindexed_array, function(n, i){
							indexed_array[n['name']] = n['value'];
						});
						Cookies.set( 'add_cart_edo', JSON.stringify(indexed_array) );
						$('.open_register').click();
					}
				});

				$('body .single_add_to_cart_button').click( function ( e ) {
					$this_btn = $(this);
					if ( site_params.is_user_logged_in === '1' ) {
						if ( !open_completar ) {
							if ( site_params.open_complete_data != '1' ) {
								e.preventDefault();
								_this.open_completar_registro();
							}
						}
					}
					else {
						e.preventDefault();
						var indexed_array = {};
						var unindexed_array = $('.woocommerce div.product form').serializeArray();
						$.map(unindexed_array, function(n, i){
							indexed_array[n['name']] = n['value'];
						});
						Cookies.set( 'add_cart_edo', JSON.stringify(indexed_array) );
						$('.open_register').click();
					}
				} );

				$('.open_login_register, .open_register').magnificPopup({
					'type': 'inline',
					'midClick': true,
					'fixedContentPos': true,
					'fixedBgPos': true,
				});
				$('#open_login_register form').submit( function ( e ) {
					var $form = $(this);
					e.preventDefault();
					$form.find('.alert').remove();
					var data = {
						'action': 'loginWithEmail',
						'email': $form.find('[name="log"]').val(),
						'password': $form.find('[name="pwd"]').val(),
					};
					jQuery.post(ajax_object.ajax_url, data, function(response) {
						console.log( response );
						if ( response.result == 'success' ) {
							$form.prepend('<div class="alert alert-success" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
							setTimeout( function ( ) {
								location.reload();
							}, 100 );
						}
						else {
							$form.prepend('<div class="alert alert-danger" style="padding-top: 0.3em; padding-bottom: 0.1em; " role="alert">'+response.msg+'</div>');
						}
					});
				} );

				$('#menu-mobile-menu .menu-item-has-children.current-menu-ancestor .sub-menu').toggle();
				
				$('#menu-mobile-menu .menu-item-has-children >a').click(function( e ){
					e.preventDefault();
					$(this).parent().toggleClass('toggle_click');	
					$(this).parent().find('.sub-menu').toggle();
					//console.log(jQuery(this).parent().find('.sub-menu'));
				});
				$('.openVideo').magnificPopup( {
					type: 'iframe',
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
				} );
				$('.navbar-toggler').magnificPopup( {
					type: 'inline',
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
					callbacks: {
						open: function() {
							$('.mfp-wrap #menu-main > .menu-item-type-custom.menu-item-has-children > a[href="#"]').click( function (e) {
								e.preventDefault();
								$('.mfp-wrap #menu-main > .menu-item-type-custom.menu-item-has-children > a[href="#"]').not(this).removeClass( "openSubMenu" );
								$( this ).toggleClass( "openSubMenu" );
							} );
						},
						beforeClose: function() {
							// Callback available since v0.9.0
							$('.mfp-wrap #menu-main > .menu-item-type-custom.menu-item-has-children > a[href="#"]').off('click'); 
						},
					}
				} );
				$('a[href="#edo-sushi-bar"]').click( function ( e ) {
					e.preventDefault();
					var hash = $(this)[0].hash;
					if ( hash && $(hash).length ) {
						$('html, body').animate({
							scrollTop: $( hash ).offset().top - sticky.height() + 1
						}, 1500);
					}
				} );
				$('a[href="#reservaShowTeppan"]').magnificPopup({
					type: 'inline' ,
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
				});
				$('#edo_widget_home .btn').magnificPopup({
					type: 'inline' ,
					midClick: true,
					fixedContentPos: true,
					fixedBgPos: true,
				});
				var sticky = $('header#banner'),
				header_h = sticky.height() * .2;
				$(window).resize(function(){
					header_h = sticky.height() * .2;

					if ( $('body').hasClass( 'woocommerce-page') ) {
						$('body').css( {
							'padding-top': sticky.height() + parseFloat( sticky.css( 'margin-bottom') )
						} );
					}
				});
				$('a[href="#banner"]').click( function ( e ) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: 0
					}, 1500);
				});
				if ( $('body').hasClass( 'woocommerce-page') ) {
					sticky.addClass('fixed');
					$('body').css( {
						'padding-top': sticky.height() + parseFloat( sticky.css( 'margin-bottom') )
					} );
				}
				$(window).scroll(function(){
					var scroll = $(window).scrollTop();
					if ( $('body').hasClass( 'woocommerce-page') ) {

					} else {
						if ( scroll >= header_h ) {
							$('body').css( {
								'padding-top': header_h
							} );
							sticky.addClass('fixed');
						}
						else {
							sticky.removeClass('fixed');
							$('body').css( {
								'padding-top': 0
							} );
						}
					}
				});

				$('[name="edo_cliente_type_telephone"]').change( function () {
					var value = $('[name="edo_cliente_type_telephone"]').val();
					switch( value ) {
						case 'fijo':
							//$('[name="edo_cliente_telephone"]').attr('maxlength', 7);
							$('[name="edo_cliente_telephone"]').val( $('[name="edo_cliente_telephone"]').data('fijo') );
							break;
							
						case 'celular':
							$('[name="edo_cliente_telephone"]').attr('minlength', 9);
							$('[name="edo_cliente_telephone"]').val( $('[name="edo_cliente_telephone"]').data('celular') );
							break;
					}
				} );
				$('[name="edo_cliente_telephone"]').change( function () {
					var value = $('[name="edo_cliente_type_telephone"]').val();
					switch( value ) {
						case 'fijo':
							$('[name="edo_cliente_telephone"]').data('fijo', $('[name="edo_cliente_telephone"]').val() );
							break;
						case 'celular':
							$('[name="edo_cliente_telephone"]').data('celular', $('[name="edo_cliente_telephone"]').val() );
							break;
					}
				} );
				$('[name="edo_cliente_telephone"]').keypress(function (e) {
					if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
						return false;
					}
				 });

				$('.input_deseo_factura').change( function () {
					if( $(this).is( ':checked' ) ) {
						$('.show_in_factura').fadeIn(0);
					} else {
						$('.show_in_factura').fadeOut(0);
						$('.show_in_factura').find('input').val('');
					}
				} );

				$('.btn-continuar, .btn-search-directions').click( function ( e ) {
					e.preventDefault();
					var $input = $(this);
					var value = $('[name="edo_cliente_type_telephone"]').val();
					switch( value ) {
						case 'fijo':
							if ( $('[name="edo_cliente_telephone"]').val().length != 7 ) {
								if ( $('[name="edo_cliente_telephone"]').val().length > 7 ) {
									//alert( edo_msj.max_limint );
								}
								else {
									//alert( edo_msj.max_limint_7 );
								}
								//return;
							}
							break;
						case 'celular':
							if ( $('[name="edo_cliente_telephone"]').val().length != 9 ) {
								if ( $('[name="edo_cliente_telephone"]').val().length > 9 ) {
									//alert( edo_msj.max_limint );
								}
								else {
									alert( edo_msj.max_limint_9 );
									return;
								}
							}
							break;
					}

					$('.glyphicon.glyphicon-refresh.glyphicon-refresh-search').fadeIn(0).css( {display: 'inline-block' } );
					$.ajax({
						type:		'POST',
						url:		ajax_object.ajax_url,
						data:		{
							action: 'find_by_telephone',
							telephone: $('[name="edo_cliente_telephone"]').val(),
						},
						dataType:	'json',
						success:	function( result ) {
							try{
								$('.glyphicon.glyphicon-refresh.glyphicon-refresh-search').fadeOut(0);

								$('.btn-siguiente-resumen').fadeIn(0);
								$('.btn-submit-add-to-cart').fadeIn(0);
								$('#completar_registro').addClass('second');
								$('.col-telefono-tipo').removeClass('col-lg-12').addClass('col-lg-6');
								if ( result.type === 'success' ) {
									var source	 = $("#result_request_telephone").html();
									var template = Handlebars.compile(source);
									var html = template({
										clientes: result.result,
									});
									$('#clietes_direcciones').html( html );
									//$('#clietes_direcciones').attr('data-direcciones', JSON.stringify( result.result ));
									$('#clietes_direcciones').attr('data-result-direcciones', JSON.stringify( result.result ));

									_this.clietes_direcciones_fun( );
									if ( $input.hasClass('btn-continuar') ) {
										$input.fadeOut(0);
									}
									if ( result.result.length ) {
										$('.form-group.form_clientes').fadeIn(0);
										$input.parent().removeClass('mb-3');
									} else {
										$input.parent().addClass('mb-3');
										$('.form_clientes input[value="-1"]').prop("checked", true);
										$('.form_clientes input[value="-1"]').change();
										$('.form-group.form_clientes').fadeOut(0);
									}
									$('.btn-submit-add-to-cart').fadeIn(0);
								} else if ( result.type === 'failure' ) {
									throw 'Result failure';
								} else {
									throw 'Invalid response';
								}
							} catch( err ){
								console.log( result );
								console.log( err );
								//$form.removeClass( 'processing' );
							}
						},
						error:	function( jqXHR, textStatus, errorThrown ) {
							$('.glyphicon.glyphicon-refresh.glyphicon-refresh-search').fadeOut(0);
							//submit_error( '<div class="message-result error">' + site_params.i18n_form_error + '</div>', $form );
							//$form.removeClass( 'processing' );
						}
					});
				} );

				$('#completar_registro_form .btn-siguiente-resumen').click( function ( e ) {
					var existsError = false;
					var $btn = $(this);
					var $form = $( '#completar_registro_form' );
					e.preventDefault();
					$('.edit_first_data_form').fadeOut(0);
					$('.open_resumen').fadeIn(0);
					$('#completar_registro > h2').fadeOut(0);

					var $select_direction = $('#clietes_direcciones .form_cliente_direcciones input[type="radio"]:checked');
					if ( $select_direction.length ) {
						if ( $select_direction.val() == -1 ) {
							$('.direccion_txt').html( $('[name="edo_cliente_direccion_direcc"]').val() );
						} else {
							if ( $select_direction.val() ) {
								$('.show_edit_address').fadeOut(0);
							}
							$('.direccion_txt').html( $select_direction.data('cliente-direccion') );
							var data_direccion = $select_direction.data('direccion');
							if ( data_direccion ) {
								$('.direccion_txt').html( data_direccion.direcc );
								//$('[name="edo_cliente_direccion_direcc_real"]').val( data_direccion.direccReal ? data_direccion.direccReal : data_direccion.direcc );
								$('[name="edo_cliente_direccion_direcc"]').val( data_direccion.direcc );
								if ( data_direccion.dist ) {
									$('[name="edo_cliente_direccion_dist"]').val( data_direccion.dist );
								}
								else {
									$('[name="edo_cliente_direccion_dist"]').val( $('[name="edo_cliente_direccion_dist"] option[data-name="'+ data_direccion.distNmb +'"]').val() );
								}
								$('[name="edo_cliente_direccion_refer"]').val( data_direccion.refer );
								_this.enabled_submit();
							}
						}
					} else {
						$('.direccion_txt').html( $('[name="edo_cliente_direccion_direcc"]').val() );
					}
					$('.desde').html( $('[name="edo_cliente_direccion_sede"]').val() );
					_this.enabled_submit();
				} );
				$('#completar_registro_form .edit_form').click( function ( e ) {
					e.preventDefault();
					//$('.open_resumen').fadeOut(0);
					$('.edit_data_form').fadeIn(0);
				} );
				$('#completar_registro_form .btn-submit-add-to-cart').click( function ( e ) {
					e.preventDefault();
					var $form = $( '#completar_registro_form' );
					var $btn = $(this);
					$btn.attr("disabled", true);
					if ( $form.hasClass('processing') ) {
						$btn.removeAttr("disabled");
						return;
					}
					$form.addClass( 'processing' );
					var existsError = false;
					$form.find('.form-control.required').each( function( index, _control ) {
						var control = $( _control );
						control.removeClass('has-error').parent().removeClass('has-error');
						if ( control.hasClass('required') && ( !control.val() || control.val() === -1 ) ){
							control.addClass('has-error').parent().addClass('has-error');
							existsError = true;
						}
					} );
					$form.find('.form-check-input.required').each( function( index, _control ) {
						var control = $( _control );
						control.removeClass('has-error').parent().removeClass('has-error');
						if ( control.hasClass('required') && !control.is(':checked') ){
							control.addClass('has-error').parent().addClass('has-error');
							existsError = true;	
						}
					} );
					if ( existsError ) {
						$form.removeClass( 'processing' );
						$btn.removeAttr("disabled");
						$btn.after('<span style="color: red; margin-left: 1em; "><br><br>Debes completar los campos obligatorios</span>');
						setTimeout( function () {
							$btn.next().remove();
						}, 3000 );
						return;
					}

					var extra_data = {};

					var $select_direction = $('#clietes_direcciones .form_cliente_direcciones input[type="radio"]:checked');
					var direccion = {
						id: '',
					};
					var locales = {
						'008': 'BASADRE',
						'007': 'EL TRIGAL',
						'005': 'JOCKEY PLAZA',
						'002': 'LA MOLINA',
						'004': 'MAGDALENA DEL MAR',
						'001': 'MIRAFLORES',
						'006': 'SAN BORJA',
						'009': 'SAN MIGUEL',
					};
					if ( $select_direction.length ) {
						direccion = {
							id: '',
							//direccReal: $('#completar_registro_form [name="edo_cliente_direccion_direcc_real"]').val(),
							direcc: $('#completar_registro_form [name="edo_cliente_direccion_direcc"]').val(),
							refer: $('#completar_registro_form [name="edo_cliente_direccion_refer"]').val(),
							dist: $('#completar_registro_form [name="edo_cliente_direccion_dist"]').val(),
							distNmb: $('#completar_registro_form [name="edo_cliente_direccion_dist"] option:checked').text(),
							dpto: '',
						};
						if ( $select_direction.val() == -1 ) {

						} else {
							var data_direccion = $select_direction.data('direccion');
							if ( data_direccion ) {
								direccion.id = data_direccion.id;
							}
						}
					} else {
						direccion = {
							id: '',
							//direccReal: $('#completar_registro_form [name="edo_cliente_direccion_direcc_real"]').val(),
							direcc: $('#completar_registro_form [name="edo_cliente_direccion_direcc"]').val(),
							refer: $('#completar_registro_form [name="edo_cliente_direccion_refer"]').val(),
							dist: $('#completar_registro_form [name="edo_cliente_direccion_dist"]').val(),
							distNmb: $('#completar_registro_form [name="edo_cliente_direccion_dist"] option:checked').text(),

							dpto: '',
						};
					}
					extra_data.edo_cliente_data_direccion = direccion;

					
					var result_direcciones = $('#clietes_direcciones').data('result-direcciones');
					var $select_cliente = $('#clietes_direcciones .form_clientes input[type="radio"]:checked');
					if ( $select_cliente.length ) {
						if ( $select_cliente.val() == -1 ) {
							var $cliente_alergico = $('#completar_registro_form [name="edo_cliente_alergico"]');
							var $cliente_alergia = $('#completar_registro_form [name="edo_cliente_alergia"]');
							var $cliente_dia_cumpleanos = $('#completar_registro_form [name="edo_cliente_dia_cumpleanos"]');
							var $cliente_mes_cumpleanos = $('#completar_registro_form [name="edo_cliente_mes_cumpleanos"]');
							extra_data.edo_cliente_data = {
								cliente: '',
								nombre: $('#completar_registro_form [name="edo_cliente_nombre"]').val(),
								ruc: $('#completar_registro_form [name="edo_cliente_ruc"]').val(),
								razonSocial: $('#completar_registro_form [name="edo_cliente_razon_social"]').val(),
								telefono: $('#completar_registro_form [name="edo_cliente_fijo"]').val(),
								celular: $('#completar_registro_form [name="edo_cliente_celular"]').val(),
								diaCumple: $cliente_dia_cumpleanos.val() != -1 ? $cliente_dia_cumpleanos.val() : 0 ,
								mesCumple: $cliente_mes_cumpleanos.val() != -1 ? $cliente_mes_cumpleanos.val() : 0 ,
								//direccionReal: $('#completar_registro_form [name="direccion_direcc_real"]').val(),
								direccion: $('#completar_registro_form [name="edo_cliente_direccion_direcc"]').val(),
								referencia: $('#completar_registro_form [name="edo_cliente_direccion_refer"]').val(),
								idDistrito: $('#completar_registro_form [name="edo_cliente_direccion_dist"]').val(),
								alergico: $cliente_alergico.val() == 'no' ? 0 : ( $cliente_alergia.val() != -1 ? $cliente_alergia.val() : 0 ),
								idTipoAlergia: $cliente_alergico.val() == 'no' ? 0 : ( $cliente_alergia.val() != -1 ? $('#completar_registro_form [name="edo_cliente_alergia"] option:checked').text() : 0 ),
								Direcciones: [ ],
							};
							extra_data.edo_cliente_data.direccion = extra_data.edo_cliente_data_direccion.direcc;
							extra_data.edo_cliente_data.referencia = extra_data.edo_cliente_data_direccion.refer;
							extra_data.edo_cliente_data.idDistrito = extra_data.edo_cliente_data_direccion.dist;
							extra_data.edo_cliente_data.Direcciones.push( direccion );
						} else {
							var direcciones = $select_cliente.data('direcciones');
							if ( user_data && user_data.edo_cliente_data ) {
								extra_data.edo_cliente_data = user_data.edo_cliente_data;
							}
							else {
								extra_data.edo_cliente_data = result_direcciones[$select_cliente.data('index-cliente')];
							}
							if ( direcciones ) {
							}
							extra_data.edo_cliente_data.Direcciones = direcciones;

							if ( extra_data.edo_cliente_data && !extra_data.edo_cliente_data.Direcciones ) {
								extra_data.edo_cliente_data.Direcciones = [];
							}
							
							extra_data.edo_cliente_data.direccion = extra_data.edo_cliente_data_direccion.direcc;
							extra_data.edo_cliente_data.referencia = extra_data.edo_cliente_data_direccion.refer;
							extra_data.edo_cliente_data.idDistrito = extra_data.edo_cliente_data_direccion.dist;

							var index_direct = -1;
							extra_data.edo_cliente_data.Direcciones.some(function(entry, i) {
								if (entry.id == direccion.id) {
									index_direct = i;
									return true;
								}
							});
							if ( index_direct != -1 ) {
								extra_data.edo_cliente_data.Direcciones[index_direct].direcc = direccion.direcc;
								extra_data.edo_cliente_data.Direcciones[index_direct].refer = direccion.refer;
								extra_data.edo_cliente_data.Direcciones[index_direct].dist = direccion.dist;
								extra_data.edo_cliente_data.Direcciones[index_direct].distNmb = direccion.distNmb;
							} else {
								extra_data.edo_cliente_data.Direcciones.push( direccion );
							}
						}
					}

					
					if ( $form.find('[name="edo_cliente_direccion_sede"]').val() == '' ) {
						$btn.after('<span style="color: red; margin-left: 1em; "><br><br>Lo sentimos su dirección está fuera del área de cobertura para delivery</span>');
						$btn.removeAttr("disabled");
						setTimeout( function () {
							$btn.next().remove();
						}, 3000 );
						return;
					}

					//if ( !confirm('Se procederá a registrar los datos proporcionados, por favor verifique si son correctos') ) {
					//	$btn.removeAttr("disabled");
					//	return;
					//}
					$('.glyphicon.glyphicon-refresh.glyphicon-refresh-save').fadeIn(0).css( {display: 'inline-block' } );
					$.ajax({
						type:		'POST',
						url:		ajax_object.ajax_url,
						data:		$form.serialize()+ '&' + $.param( extra_data ),
						dataType:	'json',
						success:	function( result ) {
							try{
								console.log( result );
								if ( result.type === 'success' ) {
									$form.removeClass( 'processing' );
									$btn.removeAttr("disabled");
									$('.glyphicon.glyphicon-refresh.glyphicon-refresh-save').fadeOut(0);
									$btn.after('<span style="color: red; margin-left: 1em; "><br><br>'+ result.msg +'</span>');
									$form[0].reset();
									setTimeout( function () {
										$btn.next().remove();
										site_params.open_complete_data = '1';
										open_completar = true;
										if( $this_btn ) {
											$this_btn.click();
											$this_btn = false;
										}
										$.magnificPopup.close();
									}, 3000 );
								} else if ( result.type === 'failure' ) {
									throw 'Result failure';
								} else {
									throw 'Invalid response';
								}
							} catch( err ){
								console.log( err );
								$('.glyphicon.glyphicon-refresh.glyphicon-refresh-save').fadeOut(0);
								$form.removeClass( 'processing' );
								$btn.removeAttr("disabled");
								$btn.after('<span style="color: red; margin-left: 1em; "><br><br>'+ result.msg +'</span>');
								setTimeout( function () {
									$btn.next().remove();
								}, 3000 );
							}
						},
						error:	function( jqXHR, textStatus, errorThrown ) {
							//submit_error( '<div class="message-result error">' + site_params.i18n_form_error + '</div>', $form );
							$('.glyphicon.glyphicon-refresh.glyphicon-refresh-save').fadeOut(0);
							$form.removeClass( 'processing' );
							$btn.removeAttr("disabled");
							$btn.after('<span style="color: red; margin-left: 1em; "><br><br>'+ site_params.i18n_form_error +'</span>');
							setTimeout( function () {
								$btn.next().remove();
							}, 3000 );
						}
					} );
				} );
				if ( site_params.is_user_logged_in ) {
					if ( site_params.open_complete_data != '1' ) {
						//_this.open_completar_registro();
					}
				}

				var $window = $(window);
				var resize = false;
				var timer;
				$('.flexslider').on('resize',function(event){
					//event.stopPropagation();
				});

				$window.resize(function() {
					clearTimeout(timer);
					timer = setTimeout( function(){
						console.log('resize');
						var slider1 = $('.flexslider').data('flexslider');
						slider1.resize();
					}, 500);
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				var map;
				/*
				if ( window.innerWidth > 767 ) {
					$('#show_teppan').css({
						'background-position':	20 + ( parseFloat( $('#show_teppan').width() / 2 )	+ parseFloat( $('#show_teppan').css('padding-left') ) ) +'px',
					});
				}
				else {
					$('#show_teppan').removeAttr('style');
				}
				*/
				$('.wpcf7-form input[name="fecha"]').attr('readonly', 'readonly');
				var current  = new Date();
				$('.wpcf7-form input[name="fecha"]').datepicker({
					language: "es",
					format: "dd/mm/yyyy",
					endDate: "01/01/" + ( current.getYear() + 1901 ),
					weekStart: 1,
					daysOfWeekDisabled: "0,5,6",
					autoclose: true,
					datesDisabled: ['01/01/2017', '13/04/2017', '14/04/2017', '16/04/2017', '01/05/2017', '29/06/2017', '30/06/2017', '27/07/2017', '28/07/2017', '29/07/2017', '30/08/2017', '08/10/2017', '01/11/2017', '08/12/2017', '25/12/2017']
				});
				/*
				$('#locales-slider .maps').each( function ( index, map ) {
					var directions = {lat: $(map).data('lat'), lng: $(map).data('lng')};
					map = new google.maps.Map(document.getElementById( $(map).data('map-id') ), {
						zoom: 14,
						center: directions,
						scrollwheel: false,
						navigationControl: false,
						mapTypeControl: false,
						scaleControl: false,
						draggable: false,
					});
					var marker = new google.maps.Marker({
						position: directions,
						map: map
					});
				} );
				*/
				$('#locales-carousel').flexslider({
					animation: "slide",
					slideshowSpeed: 4800,
					animationSpeed:400,
					controlNav: false,
					animationLoop: true,
					slideshow: false,
					itemWidth: isMobile.any ? ( isMobile.tablet ? 125 : 120 ) : 185,
					itemMargin: 0,
					asNavFor: '#locales-slider'
				});
				$('#locales-slider').flexslider({
					animation: "slide",
					slideshowSpeed: 4800,
					animationSpeed:400,
					controlNav: false,
					directionNav: false,
					animationLoop: true,
					slideshow: false,
					sync: "#locales-carousel"
				});
				/*
				$(window).resize( function () {
					if ( window.innerWidth > 767 ) {
						$('#show_teppan').css({
							'background-position': 20 + ( parseFloat( $('#show_teppan').width() / 2 )	+ parseFloat( $('#show_teppan').css('padding-left') ) ) +'px',
						});
					}
					else {
						$('#show_teppan').removeAttr('style');
					}
				} );
				*/
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'product_type_ebp_package': {

			calculatePrice: function () {
				var final_price = 0;
				$('.qty-variable').each( function ( index, item ) {
					if ( $(item).val() >=1 ) {
						console.log( $(item).val(), $(item).data('price') );
						final_price += parseFloat( $(item).data('price') ) * $(item).val();
					}
				} );
				$('.woocommerce-Price-amount').html( parseFloat(final_price).toFixed(2) );
				return final_price;
			},
			init: function() {
				var _this = this;
				/*
				_this.calculatePrice();
				$('.qty-variable').bind('keyup mouseup', function () {
					var final_price = _this.calculatePrice();
					console.log( final_price );
				});
				*/
			}
		},
		// About us page, note the change from about-us to about_us.
		'single_product': {
			flexsliderProducts: { vars:{} },
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function () {
				var _this = this;
				$('#myTab a:first').tab('show');

				$('.widget_products_slider .flexslider, .releted_products.flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 192,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 4, 4, 4),
					maxItems: _this.getGridSize(2, 2, 3, 4, 4, 4),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
					}
				});
			},
			init: function() {
				var _this = this;
				var $window = $(window);
				_this.initSliders();
				$window.resize(function() {
					var gridSizeProducts = _this.getGridSize( 2, 2, 3, 4, 4, 4 );
					_this.flexsliderProducts.vars.minItems = gridSizeProducts;
					_this.flexsliderProducts.vars.maxItems = gridSizeProducts;
				});
			}
		},
		// About us page, note the change from about-us to about_us.
		'post_type_archive_product': {
			flexsliderProducts: { vars:{} },
			getGridSize: function( xs, sm, md, lg, xl, xxl ) {
				return ( window.innerWidth < 544 ) ? xs : ( window.innerWidth < 768 ) ? sm : ( window.innerWidth < 992 ) ? md : ( window.innerWidth < 1260 ) ? lg: ( window.innerWidth < 1440 ) ? xl: xxl;
			},
			initSliders: function () {
				var _this = this;
				$('.widget_products_slider .flexslider, .releted_products.flexslider').flexslider({
					animation: "slide",
					//animationLoop: false,
					itemWidth: 192,
					pauseOnAction: false,
					pauseOnHover: true,
					itemMargin: 0,
					minItems: _this.getGridSize(2, 2, 3, 4, 4, 4),
					maxItems: _this.getGridSize(2, 2, 3, 4, 4, 4),
					controlNav: false,
					animationLoop: true,
					prevText: "",
					nextText: "",
					//mousewheel: true,
					start: function(slider){
						$('body').removeClass('loading');
						_this.flexsliderProducts = slider;
					}
				});
			},
			init: function() {
				var _this = this;
				var $window = $(window);
				_this.initSliders();
				$window.resize(function() {
					var gridSizeProducts = _this.getGridSize( 2, 2, 3, 4, 4, 4 );
					_this.flexsliderProducts.vars.minItems = gridSizeProducts;
					_this.flexsliderProducts.vars.maxItems = gridSizeProducts;
				});

				$(document).bind('scroll',function(e){
					var offset = $('header#banner').height();
					$('#sidebar-tienda section.widget').each(function(){
						if ($(this).offset().top < window.pageYOffset + offset && $(this).offset().top + $(this).height() > window.pageYOffset + offset ) {
							window.location.hash = $(this).attr('id');
						}
					});
				});
			}
		},
		'woocommerce_cart': {
			init: function () {
				$(".woocommerce-cart-form .qty").bind('keyup mouseup', function () {
					$('.woocommerce-cart-form').submit()
				});
				$( document.body ).on( 'updated_cart_totals', function () {
					$(".woocommerce-cart-form .qty").bind('keyup mouseup', function () {
						$('.woocommerce-cart-form').submit()
					});
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
